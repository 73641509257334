import React from 'react';

export function VenuesIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="inherit"
      fillOpacity="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 7.5C9.5 6.6875 8.8125 6 8 6C7.125 6 6.5 6.6875 6.5 7.5V20.5C6.5 21.375 7.125 22 8 22C8.8125 22 9.5 21.375 9.5 20.5V7.5ZM14 6C13.125 6 12.5 6.6875 12.5 7.5V20.5C12.5 21.375 13.125 22 14 22C14.8125 22 15.5 21.375 15.5 20.5V7.5C15.5 6.6875 14.8125 6 14 6ZM28 8.5C28 6.0625 25.9375 4 23.5 4H22V3C22 1.375 20.625 0 19 0H3C1.3125 0 0 1.375 0 3V25C0 26.6875 1.3125 28 3 28H19C20.625 28 22 26.6875 22 25V23.25L25.375 21.6875C26.9375 20.9375 28 19.375 28 17.625V8.5ZM18.625 3C18.8125 3 19 3.1875 19 3.375V24.625C19 24.875 18.8125 25 18.625 25H3.3125C3.125 25 3 24.875 3 24.6875V3.375C3 3.1875 3.125 3 3.375 3H18.625ZM25 8.5V17.625C25 18.1875 24.625 18.75 24.125 19L22 19.9375V7H23.5C24.3125 7 25 7.6875 25 8.5Z"
        fill="inherit"
        fillOpacity="inherit"
      />
    </svg>
  );
}

export function VenuesGlowIcon() {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path
          d="M15.8571 13.5714C15.8571 12.6429 15.0714 11.8571 14.1429 11.8571C13.1429 11.8571 12.4286 12.6429 12.4286 13.5714V28.4286C12.4286 29.4286 13.1429 30.1429 14.1429 30.1429C15.0714 30.1429 15.8571 29.4286 15.8571 28.4286V13.5714ZM21 11.8571C20 11.8571 19.2857 12.6429 19.2857 13.5714V28.4286C19.2857 29.4286 20 30.1429 21 30.1429C21.9286 30.1429 22.7143 29.4286 22.7143 28.4286V13.5714C22.7143 12.6429 21.9286 11.8571 21 11.8571ZM37 14.7143C37 11.9286 34.6429 9.57143 31.8571 9.57143H30.1429V8.42857C30.1429 6.57143 28.5714 5 26.7143 5H8.42857C6.5 5 5 6.57143 5 8.42857V33.5714C5 35.5 6.5 37 8.42857 37H26.7143C28.5714 37 30.1429 35.5 30.1429 33.5714V31.5714L34 29.7857C35.7857 28.9286 37 27.1429 37 25.1429V14.7143ZM26.2857 8.42857C26.5 8.42857 26.7143 8.64286 26.7143 8.85714V33.1429C26.7143 33.4286 26.5 33.5714 26.2857 33.5714H8.78571C8.57143 33.5714 8.42857 33.4286 8.42857 33.2143V8.85714C8.42857 8.64286 8.57143 8.42857 8.85714 8.42857H26.2857ZM33.5714 14.7143V25.1429C33.5714 25.7857 33.1429 26.4286 32.5714 26.7143L30.1429 27.7857V13H31.8571C32.7857 13 33.5714 13.7857 33.5714 14.7143Z"
          fill="inherit"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="42"
          height="42"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.698039 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
