import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CloseIcon, FacebookIcon, InstagramIcon } from './icons';
import './Menu.scss'



const Menu = ({ onClose }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  useEffect(() => {
    setTimeout(() => setIsOpen(true), 100)
    
  }, [])

  const close = () => {
    setIsOpen(false)
    setTimeout(() => onClose(), 200)
  }
  return (
    <div className={isOpen ? "menu-wrapper open" : "menu-wrapper"}>
      <button onClick={close}><CloseIcon /></button>
      <Link onClick={close} to="/">Home</Link>
      <Link onClick={close} to="/book-skimpies">Book a skimpy</Link>
      <Link onClick={close} to="/about-us">About us</Link>
      <Link onClick={close} to="/contact-us">Contact</Link>
      <Link onClick={close} to="/work-with-us">Work with us</Link>
      <Link onClick={close} to="/advertising">Advertising</Link>

      <div>
        <a href='https://www.facebook.com/groups/skimpyheavenperth/' onClick={close}>
          <FacebookIcon /> 
        </a>
        <a href='https://www.instagram.com/skimpyheaven/' onClick={close}>
          <InstagramIcon />
        </a>
      </div>
    </div>
  );
};

export default Menu;
