import React, { useEffect, useRef, useState, useMemo } from 'react';
import flatpickr from 'flatpickr';
import { ChevronLeftIcon, ChevronRightIcon } from './icons';
import dayjs from 'dayjs';
import './InlineFlatpickr.scss';

const DAY_MAP = {
  0: 'S',
  1: 'M',
  2: 'T',
  3: 'W',
  4: 'T',
  5: 'F',
  6: 'S',
};

const InlineFlatpickr = ({ value, onValueChanged, options }) => {
  const ref = useRef(null);
  const [instance, setInstance] = useState(null);
  const [monthStr, setMonthStr] = useState('');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [viewingMonth, setViewingMonth] = useState(new Date());

  const [isPrevMonthDisabled, isNextMonthDisabled] = useMemo(() => {
    const djsViewMonth = dayjs(viewingMonth).startOf('month')
    const minMonth = options && options.minDate ? dayjs(options.minDate).startOf('month') : dayjs().startOf('month')
    const maxMonth = options && options.maxDate ? dayjs(options.maxDate).startOf('month') : dayjs().startOf('month')
    const prevDisabled = djsViewMonth.isSame(minMonth, 'month')
    const nextDisabled = djsViewMonth.isSame(maxMonth, 'month')
    
    return [prevDisabled, nextDisabled]
  }, [viewingMonth, options])

  useEffect(() => {
    if (ref && ref.current && !instance) {
      const opts = {
        inline: true,
        minDate: options ? options.minDate || dayjs().startOf('day').toDate() : dayjs().startOf('day').toDate(),
        maxDate: options ? options.maxDate : null,
        appendTo: ref.current,
        onChange: (selectedDates, dateStr, i) => {
          setCurrentDate(selectedDates[0]);
          if (onValueChanged) {
            onValueChanged(selectedDates[0]);
          }
        },
        ...(options || {}),
      };
      const t = flatpickr(ref.current, opts);
      t.setDate(value || new Date(), true);
      setInstance(t);
    }
  }, [ref, instance, value, onValueChanged, options]);

  const changeMonth = (inc) => {
    if (inc) {
      instance.changeMonth(inc);
    }
    setViewingMonth(dayjs(viewingMonth).startOf('month').add(inc, 'month').toDate());
  };

  const incMonth = () => changeMonth(1);
  const decMonth = () => changeMonth(-1);

  useEffect(() => {
    if (!!currentDate) {
      setViewingMonth(dayjs(currentDate).startOf('month').toDate());
    }
  }, [currentDate]);

  useEffect(() => {
    if (!!viewingMonth) {
      setMonthStr(dayjs(viewingMonth).format('MMMM'));
    }
  }, [viewingMonth]);

  return (
    <div className="inline-flatpickr--container" ref={ref}>
      <div className="custom-months">
        <button onClick={decMonth} className={isPrevMonthDisabled ? 'disabled' : ''}>
          <ChevronLeftIcon />
        </button>
        <label>{monthStr}</label>
        <button onClick={incMonth} className={isNextMonthDisabled ? 'disabled' : ''}>
          <ChevronRightIcon />
        </button>
      </div>
      <div className="custom-weekdays">
        {Object.keys(DAY_MAP).map((m) => (
          <span>{DAY_MAP[m]}</span>
        ))}
      </div>
    </div>
  );
};

export default InlineFlatpickr;
