import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CallMeSoon from '../../../components/CallMeSoon';
import ContactActionButton from '../../../components/ContactActionButton';
import SkimpyPreview from '../../../components/SkimpyPreview';
import TabHeader from '../../../components/TabHeader';
import { ApiDataContext, ShiftMap } from '../../../contexts/ApiDataContext';
import { EnquirySubjects } from '../../../models/Email';
import { Skimpy } from '../../../models/Skimpies';
import ShiftBlock from './ShiftBlock';
import './SkimpyDetails.scss';

const SkimpyDetails = () => {
  const { id } = useParams<any>();
  const { getSkimpy, getSkimpyShifts } = useContext(ApiDataContext);
  const [skimpy, setSkimpy] = useState<Skimpy | null>(null);
  const [dates, setDates] = useState<string[]>([]);
  const [shiftsMap, setShiftsMap] = useState<ShiftMap>({});

  useEffect(() => {
    if (!!id && getSkimpy) {
      const t = getSkimpy(+id);
      if (t) {
        setSkimpy(t);
      } else {
        console.error('skimpy not found for id', id);
      }
    }
  }, [id, getSkimpy]);

  useEffect(() => {
    if (!!skimpy && getSkimpyShifts) {
      const [_dates, _shiftMap] = getSkimpyShifts(skimpy.skimpyId);
      setDates(_dates);
      setShiftsMap(_shiftMap);
    }
  }, [skimpy, getSkimpyShifts]);

  return skimpy ? (
    <div className="page-content skimpy-details-page">
      <TabHeader />
      <h2>{skimpy.stageName}</h2>
      <SkimpyPreview skimpyName={skimpy.stageName} skimpyImage={skimpy.imagePath || ''} />
      {!!dates && dates.length ? (
        <>
          <h2>Come See Me</h2>
          {dates.map((m) => (
            <ShiftBlock date={m} shifts={shiftsMap[m]} key={m} />
          ))}
        </>
      ) : (
        []
      )}
      <CallMeSoon />
      <ContactActionButton text={[`Message us about`, skimpy.stageName]} to={`/contact-us?subject=${EnquirySubjects.MessageSkimpy}&extra=${skimpy.stageName}`} />
    </div>
  ) : (
    <div>...loading...</div>
  );
};

export default SkimpyDetails;
