import React from 'react';

import WorkWithUsImage from '../../assets/pngs/work_with_us.jpg';
import { Link } from 'react-router-dom';
import { EnquirySubjects } from '../../models/Email';

const WorkWithUs = () => {
    return (
        <div className="static-page static-text">
            <h2>Work With Us</h2>
            <img src={WorkWithUsImage} alt="girl pouring beer" />
            <p>
                Hey ladies! Do you want to have fun and make a great income while working in hospitality in Western Australia? We are always looking for hot new
                talent to join our fantastic team. If you are attractive, organised, sociable, and looking for a chance to make shockingly good money with
                completely flexible hours, this could be the next opportunity for you.
            </p>
            <p>
                Skimpy Heaven offers the best hourly rates in this industry in all of Australia, and has collected a huge portfolio of jobs to book into. Skimpy
                work can improve your financial situation drastically, as it has done for many other ladies before you. If you live in WA or are happy to
                relocate here, and you have the looks, body and sociability for skimpy work, you'd be mad not to give it a go. It doesn’t matter if you have no
                experience.
            </p>
            <p>
                Bar training in a variety of locations can be provided to applicants who don’t have bartending experience. While bar skills are very important
                to WA skimpy work, some of our bookings are purely table waitressing, or dancing. Our office in Osborne Park will give all the support you need
                to set yourself up as a mobile skimpy entertainer, including affordable or loaned costumes.
            </p>
            <p>
                Having a car and license is not essential, but it helps a lot if you are based in Perth’s metropolitan area. If you travel the state
                continuously with our country jobs, they provide free accommodation, travel subsidies and other perks like free meals. Skimpy Heaven even offers
                affordable and lovely bridging accommodation for travellers near Scarborough. You don’t have to commit to any dates or locations you don’t want
                to, and can take breaks at any time. You can easily arrange skimpy jobs around your existing commitments.
            </p>
            <p>
                Our clients are looking for outstanding individuals who look fabulous and have a great way with people. Some new ladies think they have to have
                big boobs to become a skimpy barmaid or topless waitress, but it’s not true! Being a great skimpy is more about having a likeable outgoing
                personality, and a well-styled and clean presentation, plus a good work ethic. You must be highly capable at organizing your own schedule and
                transportation and be reliable. We promote a drug-free and abuse-free work environment.
            </p>
            <p>
                <strong>
                    <a href="tel:+61435745679" className="contact-link">
                        Contact us on 0435SKIMPY (0435 745 679)
                    </a>{' '}
                    or{' '}
                    <a href="mailto:admin@skimpyheaven.com.au" className="contact-link">
                        admin@skimpyheaven.com.au
                    </a>
                </strong>{' '}
                to be sent an online application form and start learning how skimpy work can benefit you. You can also Find Any Skimpy Anywhere Any Day through
                this website, and we encourage you to go and see any of our Skimpy Heaven team working at a venue near you. Ask them what it’s like to be in
                Skimpy Heaven, and they’ll tell you, we’re living the dream!
            </p>
            <h3>OUR SKIMPIES SAY...</h3>
            <q>
                I cannot thank you ladies enough for helping me get into skimpy work, I just paid off all my debts and bought a decent car, WITH CASH! I’m
                having so much fun I actually can’t believe I get paid to do this. I thought I always had to struggle and work long hours to make enough money
                to get ahead. Now I’m finally getting to prioritise my health and my little girl, and only work a fraction of the time but make many times the
                wage I used to. Thank you, thank you, thank you it’s actually changed my life. You ladies do amazing work. So grateful.
            </q>
            <Link className="btn" to={`/contact-us?subject=${EnquirySubjects.WorkWithUs}`}>
                Get in touch
            </Link>
        </div>
    );
};

export default WorkWithUs;
