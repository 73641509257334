import React, { useEffect, useState } from 'react';
import { CaretDownIcon, DateSolidIcon, SendIcon } from '../../components/icons';
import SkimpySpinner from '../../components/SkimpySpinner';
import { Email, defaultSubject, EnquirySubjects } from '../../models/Email';
import { postEmailEnquiry } from '../../services/http.svc';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import dayjs from 'dayjs';

import { Link, useHistory } from 'react-router-dom';

import './BookSkimpy.scss';
import ModalFlatpickr from '../../components/ModalFlatpickr';

const errMsg = ({ message }: any) => <label>{message}</label>;

type Extras = {
  date: Date;
  time: Date;
  occasion: string;
  eventLocation: string;
  numberOfSkimpies: string;
  skimpyNames: string;
};
type BookingType = Email & Extras;

type SelectItem = {
  id: number | string;
  value: string;
};

const buildTimes = (isToday: boolean = false): SelectItem[] => {
  let start = isToday ? dayjs().startOf('hour').add(1, 'hour') : dayjs().startOf('day');
  const end = dayjs().endOf('day')
  const tmp: SelectItem[] = [];

  while (start.isBefore(end)) {
    tmp.push({
      id: start.toDate().getTime(),
      value: start.format('hh:mmA'),
    });
    start = start.add(30, 'minute');
  }

  return tmp;
};

const extractPayload = (obj: BookingType): Email => {
  const extras: string[] = [];
  extras.push(`Date: ${dayjs(obj.date).format('DD MMM YYYY')}`);
  extras.push(`Approximate Time: ${dayjs(+obj.time).format('hh:mmA')}`);
  extras.push(`Occasion: ${obj.occasion || 'not supplied'}`);
  extras.push(`Event location: ${obj.eventLocation}`);
  extras.push(`Number of Skimpies required: ${obj.numberOfSkimpies || 'not supplied'}`);
  extras.push(`Requested Skimpies: ${obj.skimpyNames || 'not supplied'}`);

  const tmp: Email = {
    extras,
    name: obj.name,
    phone: obj.phone,
    email: obj.email,
    comments: obj.comments || '',
    subject: '',
  };
  return tmp;
};

const numSkimpiesOptions: SelectItem[] = [
  { id: '1', value: '1' },
  { id: '2', value: '2' },
  { id: '3', value: '3' },
  { id: '4', value: '4' },
  { id: '5', value: '5' },
  { id: '6+', value: '6+' },
];

const BookSkimpy = () => {
  const history = useHistory()
  const { register, formState, getValues, handleSubmit, trigger, reset, setValue } = useForm<BookingType>({
    mode: 'onChange',
    defaultValues: { date: new Date() },
  });
  const { isValid, errors } = formState;
  const [loading, setLoading] = useState<boolean>(false);
  const [times, setTimes] = useState<SelectItem[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [displayDate, setDisplayDate] = useState<string>('');
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  useEffect(() => {
    setTimes(buildTimes(true));
  }, []);

  useEffect(() => {
    if (selectedDate) {
      setTimes(buildTimes(dayjs(selectedDate).isSame(dayjs(), 'day')))
      setDisplayDate(dayjs(selectedDate).format('DD MMM YYYY'));
    }
  }, [selectedDate, setDisplayDate]);

  const submit = (data: any, e: any) => {
    (async () => {
      await trigger();
      console.log('submit', data, isValid);
      if (isValid) {
        try {
          setLoading(true);
          const payload = extractPayload(data as BookingType);
          payload.subject = defaultSubject(EnquirySubjects.BookSkimpies);
          await postEmailEnquiry(payload);
          reset();
          history.push('/confirmation')
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      }
    })();
  };

  const submitErr = (errors: any, e: any) => {
    console.error('submitErr', errors, e);
  };

  const onDateChanged = (date: Date) => {
    setValue('date', date);
    setSelectedDate(date);
  };


  return (
    <>
      <div className="form-page book-skimpies">
        <h2>Book Skimpies</h2>
        <h3 className="servicing-wa">Servicing all of WA!</h3>
        <p className="travel-charges">Travel charges may apply</p>

        <form onSubmit={handleSubmit(submit, submitErr)} noValidate>
          <label className="date-field" htmlFor="date_proxy">
            Date of Event *<ErrorMessage name="date" errors={errors} render={errMsg} />
            <input type="hidden" {...register('date', { required: ' required' })} />
          </label>
          <div className="date-field" onClick={() => setShowDatePicker(true)}>
            <label>{displayDate}</label>
            <DateSolidIcon />
            {!!showDatePicker ? (
              <ModalFlatpickr
                value={getValues('date')}
                onValueChanged={onDateChanged}
                options={{}}
                onClose={() => setTimeout(() => setShowDatePicker(false))}
              />
            ) : (
              []
            )}
          </div>

          <label className="time-field" htmlFor="time">
            Approximate Time *<ErrorMessage name="time" errors={errors} render={errMsg} />
          </label>
          <div className="select-wrapper">
            {/* NOTE: they may not want a browser supplied select list - but there were no styles for it in Figma */}
            <select {...register('time', { required: ' required' })}>
              {times.map((m) => (
                <option key={m.id} value={m.id}>
                  {m.value}
                </option>
              ))}
            </select>
            <CaretDownIcon />
          </div>

          <label htmlFor="occasion">Occasion</label>
          <input placeholder="Birthday, Buck’s Night..." {...register('occasion')} />

          <label htmlFor="eventLocation">
            Where will the event take place? *<ErrorMessage name="eventLocation" errors={errors} render={errMsg} />
          </label>
          <input placeholder="Address or area" {...register('eventLocation', { required: ' is required' })} />

          <label htmlFor="numberOfSkimpies">Number of Skimpies Required</label>
          <div className="select-wrapper">
            <select placeholder="1" {...register('numberOfSkimpies')} className="skimpies-required">
              {numSkimpiesOptions.map((m) => (
                <option key={m.id} value={m.id}>
                  {m.value}
                </option>
              ))}
            </select>
            <CaretDownIcon />
          </div>

          <label htmlFor="skimpyNames">
            Requested Skimpies
            <Link className="to-skimpies" to={'/skimpies'} target="_blank">
              View Girls Here
            </Link>
          </label>
          <input placeholder="Skimpy Names" {...register('skimpyNames')} />

          <label htmlFor="name">
            Your Name *<ErrorMessage name="name" errors={errors} render={errMsg} />
          </label>
          <input placeholder="Enter your name" {...register('name', { required: ' is required' })} />

          <label htmlFor="phone">
            Phone Number *<ErrorMessage name="phone" errors={errors} render={errMsg} />
          </label>
          <input
            type="tel"
            placeholder="+61..."
            {...register('phone', {
              required: ' is required',
              pattern: { value: /^[+]{0,1}[0-9]{8,10}$/, message: ' 8 to 10 digits required' },
            })}
          />

          <label htmlFor="email">
            Email Address *<ErrorMessage name="email" errors={errors} render={errMsg} />
          </label>
          <input
            type="email"
            placeholder="example@mail.com"
            {...register('email', {
              required: ' is required',
              pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, message: ' invalid format' },
            })}
          />

          <label htmlFor="comments">
            Comments
          </label>
          <textarea
            placeholder="Additional information to help us accurately quote you"
            {...register('comments')}
          />
          <button type="submit" className="btn blue submit">
            <SendIcon />
            Send Enquiry
          </button>
        </form>
      </div>
      {!!loading && <SkimpySpinner />}
    </>
  );
};

export default BookSkimpy;
