import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { HamburgerMenuIcon, SearchIcon } from './icons';
import logo from '../assets/pngs/skh_logo_small.png';
import './Header.scss';
import Teleporter from './Teleporter';
import Menu from './Menu';
import { ApiDataContext } from '../contexts/ApiDataContext';
import SearchList from './SearchList';
import EnvironmentBanner from './EnvironmentBanner';

const Header = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const { venues, skimpies } = useContext(ApiDataContext);

  const onSearchClicked = () => {
    setShowSearch(true);
    setTimeout(() => document.getElementById('search-input')?.focus());
  };
  const onMenuClicked = () => {
    setShowMenu(true);
  };
  return (
    <>
        <header className="header-wrapper">
          <Link to={'/'} className="logo">
            <img src={logo} alt="Skimpy Heaven brand" height="80%" />
          </Link>
          {!!showSearch ? (
            <div className="search-wrapper">
              <SearchList onClose={() => setShowSearch(false)} skimpies={skimpies} venues={venues} />
            </div>
          ) : (
            <>
              <div className="find" role="button" onClick={onSearchClicked}>
                <label>Find any skimpy.</label>
                <label>Anywhere. Any day.</label>
              </div>
              <div className="actions">
                <button className="img-btn" onClick={onSearchClicked}>
                  <SearchIcon />
                </button>
                <button className="img-btn" onClick={onMenuClicked}>
                  <HamburgerMenuIcon />
                </button>
              </div>
            </>
          )}
          <EnvironmentBanner />
        </header>

      {!!showMenu ? (
        <Teleporter selector={`#menu-portal`}>
          <Menu onClose={() => setShowMenu(false)} />
        </Teleporter>
      ) : (
        []
      )}
    </>
  );
};

export default Header;
