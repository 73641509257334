import React from 'react';

import AdvertisingImage from '../../assets/pngs/advertising_img.jpeg';
import { Link } from 'react-router-dom';
import { EnquirySubjects } from '../../models/Email';

const Advertising = () => (
    <div className="static-page static-text">
        <h2>Advertising</h2>
        <img src={AdvertisingImage} alt="2 girls in branded shorts" />
        <h3>For Venues</h3>
        <p>
            Skimpies are a fantastic financial asset to our venues. But it{`'`}s not enough to just book girls to appear - you also have to let people know when
            they{`'`}re on. Skimpy Heaven takes the hard work out of both booking and marketing skimpies, with a custom built website, massive list of freelance
            entertainers, and a huge public reach.
        </p>
        <p>
            All venues contracting skimpies through any of Skimpy Heaven{`'`}s current brand partners receive FREE automated promotion of their skimpy shifts on
            this website. (If you'd rather keep skimpies on the very down-low, it{`'`}s also possible to have your venue screened from our public webpage and rely
            on word of mouth and our social media)
        </p>
        <p>
            As well as creating this public skimpy search website, our marketing department posts on social media and contacts our fans via a growing email list
            to encourage them to visit our venue customers. Advantageously, so will most of the freelance entertainers who will appear for you
        </p>
        <p>However, with this many hotels hosting skimpies and pushing their promotions on socials, it can be hard to be seen and heard above the noise.</p>
        <p>
            So, Premium Marketing Packages are now available to venues who want to leverage the reach and following of our beautiful skimpy personalities. You
            can promote drink and meal specials, Jag the Joker, Happy Hours, band nights, ticketed events, karaoke, poker, pool comps, darts comps, and anything
            else you{`'`}re selling. We believe you should giving customers multiple reasons to come to your pub, and the awesome skimpy only makes one. We are sure
            you have many other offerings for your customers, so leverage Skimpy Heaven to tell people about them.
        </p>
        <p>
            Call us on{' '}
            <a className="contact-link" href="tel:+6145754679">
                0435SKIMPY (0435754679)
            </a>{' '}
            to talk about which level of marketing is right for your venue.
        </p>
        <h3>For Other Businesses</h3>
        <p>
            If you own a different type of business that{`'`}s non-competitive with our pubs, we can advertise your products and services to all our skimpy-loving
            public, too.
        </p>
        <p>
            We have geographically targetted ad spaces, linked to a particular venue in your area. Or you might prefer one of our higher profile Home Page or
            General Ad spaces, to reach a wider audience. Our users are interested in beer and spirits, sports and betting, entertainment, events, menswear,
            tools, food, strip clubs and more.
        </p>
        <p>
            We can arrange for select skimpies, some with huge social media reach, to do shout-outs and endorsements or even be an ongoing sponsor for your
            brand. We can customise skimpy costumes with your logo on, or help you make company branded merchandise for our army of gorgeous ladies to
            distribute. The possibilities are endless!
        </p>
        <p>Any large company interested in a Full Sponsorship is encouraged to contact us to create a package.</p>
        <p>
            Please direct all Other Business advertising enquires to{' '}
            <a className="contact-link" href="tel:+61417079397">
                0417079397
            </a>
            .
        </p>

        <h3>For Skimpies</h3>
        <p>All the beautiful ladies who appear publicly through Skimpy Heaven are respected as freelance contractors.</p>
        <p>
            That means you{`'`}re permitted to sell your own merchandise and party services direct to the public, if you wish. Every hotel skimpy shift is her
            advertisement and chance to promote herself. We can help you produce your own promotional merchandise, and raise your profile in the adult
            entertainment scene in WA in ways that translate to hard dollars in the bank.
        </p>
        <p>
            If you are a barmaid wanting to earn more money, or a freelance party waitress or showgirl who would like to get more work, we would love to hear
            from you on{' '}
            <a className="contact-link" href="tel:+6145754679">
                0435SKIMPY (0435754679)
            </a>
        </p>

        <Link className="btn" to={`/contact-us?subject=${EnquirySubjects.Advertising}`}>
            Get in touch
        </Link>
    </div>
);

export default Advertising;
