import React from 'react';
import { Link } from 'react-router-dom';

import './ContactActionButton.scss';
import Teleporter from './Teleporter';

export type ContactActionButtonProps = {
  text: string[];
  to: string;
}


const ContactActionButton = ({ text, to }: ContactActionButtonProps) => {

  return (
    <Teleporter selector={'#sticky-action'}>
      <Link to={to} className="contact-action-button">
        {text.map((m) => (
          <div key={m}>{m}</div>
        ))}
      </Link>
    </Teleporter>
  );
};

export default ContactActionButton;
