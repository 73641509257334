import React from 'react';

export function CaretDownIcon() {
  return (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.16211 0.5625C1.00195 0.5625 0.421875 1.93359 1.26562 2.72461L7.54102 9C8.01562 9.52734 8.80664 9.52734 9.33398 9L15.6094 2.72461C16.4004 1.93359 15.8203 0.5625 14.7129 0.5625H2.16211Z"
        fill="inherit"
      />
    </svg>
  );
}
