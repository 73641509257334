import React, { useContext, useEffect, useMemo } from 'react';
// import { MAP_STYLES } from './GoogleMapStyles';
import { MapContext } from '../contexts/MapContext';
import { useGoogleApiLoader } from '../hooks/useGoogleMapsApi';
import { useMap, Map as RGM } from 'rgm';

const MAP_OPTIONS = {
  zoom: 7,
  center: {
    lat: -31.9523,
    lng: 115.8613,
  },
  // disable cmd-zoom and 2 fingers zoom
  gestureHandling: 'greedy',
  clickableIcons: false,
  // styles: MAP_STYLES,
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
};

const GeoJson = () => {
  const { api, map } = useMap();
  const { geoJson } = useContext(MapContext);
  const infoWindow = useMemo(() => api ? new api.InfoWindow() : null, [api])

  useEffect(() => {
    if (!!map && !!api && !!infoWindow) {
      let venueId = null
      
      map.data.addListener('click', event => {
        venueId = event.feature.getProperty('venueId') || null
        if (venueId) {
          infoWindow.setContent([`<a href="/venues/${venueId}" style="color: black">`,event.feature.getProperty('title'), '</a>'].join(''))

        } else {
          infoWindow.setContent([`<div style="color: black">`,event.feature.getProperty('title'), '</div>'].join(''))
        }
        infoWindow.setPosition(event.feature.getGeometry().get())
        infoWindow.setOptions({pixelOffset: new api.Size(0,-30)})
        infoWindow.open(map)
      })
    }

    return () => {
      map?.data.forEach((f) => map.data.remove(f));
    };
  }, [map, api, infoWindow])

  useEffect(() => {
    if (map) {
      map.data.setStyle((f) => ({ icon: f.getProperty('icon') }));
      if (geoJson) {
        map.data.forEach((f) => map.data.remove(f));
        map.data.addGeoJson(geoJson);
        try {
          if (geoJson.features.length >= 1) {
            const {coordinates} = geoJson.features[0].geometry
            map.setCenter({lat: coordinates[1], lng: coordinates[0]})
            map.setZoom(12)
          } 
        } catch(err) {
          console.error('fitBounds', err, geoJson.features)
        }
      }
    }
  }, [map, geoJson]);
  return null;
};

const MapBlock = ({ options }) => {
  const {setMap, setApi} = useContext(MapContext)
  const api = useGoogleApiLoader();

  useEffect(() => {
    if (api && setApi) {
      setApi(api)
    }
  }, [api, setApi])

  return api ? (
    <RGM ref={setMap} api={api} options={{ ...MAP_OPTIONS, ...options }}>
      <GeoJson />
    </RGM>
  ) : null;
};

export default MapBlock;
