import React, { useEffect, useLayoutEffect, useRef, useState, useContext } from 'react';
import { AdsContext } from '../contexts/AdsContext';
import { Advert } from '../models/Advert';

import './AdBlock.scss';

const SLIDESHOW_AD_DURATION = 2000

const nextIdx = (currIdx: number, arrayLength: number): number => {
  if (currIdx >= arrayLength - 1) {
    return 0;
  }
  return currIdx + 1;
};

const hrefWithProtocol = (href: string): string => {
  if (href.indexOf('http') !== 0) {
    return `http://${href}`
  }
  return href
}

const AdBlock = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number>(0);

  const [currentAdIdx, setCurrentAdIdx] = useState<number>(-1);
  const [isSlideShow, setIsSlideShow] = useState<boolean>(false);
  const [ad, setAd] = useState<Advert | null>(null);
  const {adsToShow, setLastAdShown} = useContext(AdsContext)

  useEffect(() => {
    if (adsToShow && adsToShow.length > 0 && setCurrentAdIdx && setIsSlideShow) {
      setCurrentAdIdx(0);
      setIsSlideShow(adsToShow.length > 1);
    }
  }, [adsToShow, setCurrentAdIdx, setIsSlideShow]);

  useEffect(() => {
    if (adsToShow?.length && setAd && currentAdIdx > -1) {
      setAd(adsToShow[currentAdIdx]);
    }
  }, [adsToShow, currentAdIdx, setAd]);

  useEffect(() => {
    const idx = (): number => currentAdIdx;
    const arrLen = (): number => (adsToShow?.length ? adsToShow.length : 0);
    let interval: any = null;
    if (!!isSlideShow) {
      interval = setInterval(() => setCurrentAdIdx(nextIdx(idx(), arrLen())), SLIDESHOW_AD_DURATION);
    }

    return () => {
      if (!!interval) {
        clearInterval(interval);
      }
    };
  }, [isSlideShow, setCurrentAdIdx, adsToShow, currentAdIdx]);

  useLayoutEffect(() => {
    if (!!ref && !!ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setHeight(rect.width * 0.707113562438);
    }
  }, [ref]);

  useEffect(() => {ad && setLastAdShown && setLastAdShown(ad)}, [ad, setLastAdShown])

  return (
    <div ref={ref} className="skhad-block-wrapper" style={{ height: height }}>
      {!!ad ? (
        <a
          href={ad.hyperlink ? hrefWithProtocol(ad.hyperlink) : ''}
          onClick={(e) => !ad.hyperlink && e.preventDefault()}
          target="_blank"
          rel="noreferrer"
        >
          {ad.sas_path ? <img src={ad.sas_path} alt={ad.alt_text} /> : <>{ad.alt_text || 'ad failed to load'}</>}
        </a>
      ) : (
        []
      )}
    </div>
  );
};

export default AdBlock;
