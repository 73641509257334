import React, { useContext, useEffect, useState } from 'react';
import './Venues.scss';

import { Link } from 'react-router-dom';
import { ApiDataContext } from '../../contexts/ApiDataContext';
import ContactActionButton from '../../components/ContactActionButton';
import {SortAscendingIcon, SortDescendingIcon, ChevronRightIcon} from '../../components/icons'
import { TabBarAdBlock } from '../../components/TabBarAdBlock';
import { AdsContext } from '../../contexts/AdsContext';
import { Venue } from '../../models/Venues';

const Venues = () => {
  const [sortAscending, setSortAscending] = useState(true);
  const { venues, shifts } = useContext(ApiDataContext);
  const {setAdsVenueId} = useContext(AdsContext)
  const [venuesWithShifts, setVenuesWithShifts] = useState<Venue[]>([])
  
  useEffect(() => {setAdsVenueId && setAdsVenueId((new Date()).getTime())}, [setAdsVenueId])

  useEffect(() => {
    if (!!venues && venues.length && !!shifts && shifts.length) {
      const distinctShiftVenues: {[id: number]: boolean} = {}
      shifts.forEach(m => distinctShiftVenues[m.venueId] = true)
      const t = venues.filter(f => !!distinctShiftVenues[+f.venueId])
      setVenuesWithShifts(t)
    }
  }, [venues, shifts])

  const sort = () => {
    setSortAscending(!sortAscending);
  };

  return (
    <div className="page-content venues-page">
      <TabBarAdBlock />
      <header>
        <h3>Skimpy Venues</h3>
        <button onClick={sort}>
          Name
          {!!sortAscending ? <SortDescendingIcon /> : <SortAscendingIcon />}
        </button>
      </header>
      {venuesWithShifts && venuesWithShifts.length ? (
        <ul className={!sortAscending ? 'descending' : ''}>
          {venuesWithShifts.map((m) => (
            <li key={m.venueId}>
              <Link to={`/venues/${m.venueId}`}>
                <label>{m.name}</label>
                <label>{m.suburb}</label>
                <ChevronRightIcon />
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        []
      )}
      <ContactActionButton text={['Book Skimpies Here']} to={'/book-skimpies'} />
    </div>
  );
};

export default Venues;
