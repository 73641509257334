import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { AdsContext } from '../../../contexts/AdsContext';
import { ApiDataContext } from '../../../contexts/ApiDataContext';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../components/icons';
import { EnquirySubjects } from '../../../models/Email';
import { Shift } from '../../../models/Shifts';
import { TabBarAdBlock } from '../../../components/TabBarAdBlock';
import ContactActionButton from '../../../components/ContactActionButton';
import ShiftForDate from './ShiftForDate';
import SkimpySpinner from '../../../components/SkimpySpinner';

import './DateDetails.scss';

dayjs.extend(advancedFormat);

const DateDetails = ({ match }: any) => {
  let { id } = useParams<any>();
  const [date, setDate] = useState<Date | null>(null);
  const [links, setLinks] = useState<string[]>([]);
  const [label, setLabel] = useState<string>('');
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { setAdsVenueId } = useContext(AdsContext);
  const { shifts: allShifts, minDate, maxDate } = useContext(ApiDataContext);
  const isDayPrevDisabled = useMemo(() => {
    if (!date || !minDate) {
      return true;
    }
    return dayjs(date).startOf('day') <= dayjs(minDate).startOf('day')
  }, [date, minDate]);

  const isDayNextDisabled = useMemo(() => {
    if (!date || !maxDate) {
      return true;
    }
    return dayjs(date).endOf('day') >= dayjs(maxDate).endOf('day')
  }, [date, maxDate])

  useEffect(() => {
    setAdsVenueId && setAdsVenueId(new Date().getTime());
  }, [setAdsVenueId]);

  useEffect(() => {
    const tmp = dayjs(id).startOf('day');
    setDate(tmp.toDate());
    setLinks([tmp.add(-1, 'day').format('YYYY-MM-DD'), tmp.add(1, 'day').format('YYYY-MM-DD')]);
    setLabel(tmp.format('dddd, Do MMM'));
    setAdsVenueId && setAdsVenueId(new Date().getTime());
  }, [id, setAdsVenueId]);

  const loadShifts = useCallback(async () => {
    if (!!allShifts && !!date) {
      const dStart = dayjs(date).startOf('day').toDate().getTime();
      const dEnd = dayjs(date).endOf('day').toDate().getTime();
      const tmp = allShifts.filter((f) => f.start.getTime() >= dStart && f.start.getTime() < dEnd);
      setShifts(tmp);
      setLoading(false);
    }
  }, [date, allShifts]);

  useEffect(() => {
    if (!!date) {
      console.log(date);
      loadShifts && loadShifts();
    }
  }, [date, loadShifts]);

  return (
    <div className="page-content date-details-page">
      <TabBarAdBlock />
      {loading && <SkimpySpinner />}
      {!loading && links && links.length ? (
        <div className="nav-bar">
          <Link to={`/date/${links[0]}`} replace className={!!isDayPrevDisabled ? 'disabled' : ''}>
            <ChevronLeftIcon />
          </Link>
          <Link to={`/date`}>
            <label>{label}</label>
          </Link>
          <Link to={`/date/${links[1]}`} replace className={!!isDayNextDisabled ? 'disabled' : ''}>
            <ChevronRightIcon />
          </Link>
        </div>
      ) : (
        []
      )}
      {!!shifts && !!shifts.length ? shifts.map((m) => <ShiftForDate key={m.shiftId} shift={m} />) : []}
      <ContactActionButton
        text={['Message us about this date']}
        to={`/contact-us?subject=${EnquirySubjects.MessageDate}&extra=${label}`}
      />
    </div>
  );
};

export default DateDetails;
