import React, { createContext, useEffect, useState, useMemo, useCallback } from 'react';

import { Skimpy } from '../models/Skimpies';
import { Shift } from '../models/Shifts';
import { Venue } from '../models/Venues';
import { Video } from '../models/Video';

import * as HTTP from '../services/http.svc';

import dayjs from 'dayjs';
import { distanceInMeters } from '../services/location.svc';
import { Advert } from '../models/Advert';

export type ShiftMap = { [id: string]: Shift[] };
export type LatLng = {lat: number, lng: number}

type ApiDataContextType = {
  venues: Venue[];
  refreshVenues: () => void;
  skimpies: Skimpy[];
  refreshSkimpies: () => void;
  shifts: Shift[];
  refreshShifts: (from: Date, to: Date) => void;
  getVenue: (id: number) => Venue | null;
  getSkimpy: (id: number) => Skimpy | null;
  getVenueShifts: (id: number) => [string[], ShiftMap];
  getSkimpyShifts: (id: number) => [string[], ShiftMap];
  updatePosition: () => void,
  myPosition: LatLng | null,
  setMyPosition: (p: LatLng | null) => void,
  video: Video,
  refreshVideo: () => void,
  adverts: Advert[],
  refreshAdverts: () => void,
  videoLoading: boolean | null,
  getAds: (venueId?: number) => Advert[],
  minDate: Date,
  maxDate: Date,
  venuesWithShifts: Venue[],
  venuesWithDistances: Venue[],
};

export const ApiDataContext = createContext<Partial<ApiDataContextType>>({});

const buildShiftMap = (shifts: Shift[]): [string[], ShiftMap] => {
  const tmp: ShiftMap = {}
  shifts.forEach(shift => {
    const key = `${shift.startStr}`
    if(!tmp[key]) {
      tmp[key] = []
    }
    tmp[key].push(shift)
  })

  return [Object.keys(tmp), tmp];
}

const ApiDataProvider = ({ children }: any) => {
  const [venues, setVenues] = useState<Venue[]>([]);
  const [skimpies, setSkimpies] = useState<Skimpy[]>([]);
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [myPosition, setMyPosition] = useState<LatLng | null>(null);
  const [video, setVideo] = useState<Video>()
  const [videoLoading, setVideoLoading] = useState<boolean | null>(null)
  const [adverts, setAdverts] = useState<Advert[]>([])
  const [minDate] = useState<Date>(new Date())
  const [maxDate, setMaxDate] = useState<Date>()

  const venuesWithShifts = useMemo<Venue[]>(() => {
    if (!!venues && !!venues.length && !!shifts && shifts.length) {
      const uniqueVenueIds = shifts.map(m => m.venueId).filter((el, i, r) => r.indexOf(el) === i)
      return venues.filter((venue) => !!uniqueVenueIds.find(id => id === venue.venueId))
    }
    return []
  }, [venues, shifts])

  const venuesWithDistances = useMemo<Venue[]>(() => {
    if (!!venuesWithShifts && !!venuesWithShifts.length && !!myPosition) {
      return venuesWithShifts.map(venue => ({...venue, distanceInMeters: distanceInMeters(myPosition.lat, myPosition.lng, venue.position?.lat || 0, venue.position?.lng || 0)}))
    }
    return (venuesWithShifts || []).map(venue => ({...venue, distanceInMeters: 0}))
  }, [venuesWithShifts, myPosition])

  const refreshVenues = async () => {
    try {
      const v = await HTTP.fetchVenues();
      setVenues(v || []);
    } catch (err) {
      console.error('refreshVenues', err);
    }
  };

  const refreshSkimpies = async () => {
    try {
      const data = await HTTP.fetchSkimpies();
      (window as any).skimpies = data || [];
      setSkimpies(data || []);
    } catch (err) {
      console.error('refreshSkimpies', err);
    }
  };

  const refreshShifts = async (from: Date, to: Date) => {
    try {
      const res = await HTTP.fetchShifts(from, to);
      setShifts(res || []);
    } catch (err) {
      console.error('refreshShifts', err);
    }
  };

  const refreshVideo = async () => {
    setVideoLoading(true)
    try {
      const res = await HTTP.fetchVideo();
      !!res && setVideo(res);
    } catch (err) {
      console.error('refreshVideo', err);
    }
    setVideoLoading(false)
  };

  const refreshAdverts = async () => {
    try {
      const res = await HTTP.fetchAdverts();
      setAdverts(res || []);
    } catch (err) {
      console.error('refreshAdverts', err);
    }
  };

  const getVenue = (id: number): Venue | null => venues.find((f) => f.venueId === id) || null;

  const getSkimpy = (id: number): Skimpy | null => skimpies.find((f) => f.skimpyId === id) || null;

  const getVenueShifts = (id: number): [string[], ShiftMap] => {
    const t = shifts.filter(f => f.venueId === id)
    return buildShiftMap(t)
  }

  const getSkimpyShifts = (id: number): [string[], ShiftMap] => {
    const t = shifts.filter(f => f.skimpyId === id)
    return buildShiftMap(t)
  }

  const getAds = (venueId?: number): Advert[] => {
    const genericAds = (adverts || []).filter(f => !f.client_id)
    const randomGenericAdvert = () => genericAds.length > 0 ? genericAds[Math.floor(Math.random() * genericAds.length)] : null
    const g = randomGenericAdvert()
    if (!!venueId && venueId > 0) {
      const adsForVenue = (adverts || []).filter(f => !!f.client_id && f.client_id === venueId)
      if (adsForVenue.length >= 1) {
        return adsForVenue
      }
    }
    return g ? [g] : []
  }


  useEffect(() => {
    refreshVenues();
    refreshSkimpies();
    refreshShifts(dayjs().startOf('day').toDate(), dayjs().endOf('day').add(4, 'week').endOf('day').toDate());
    refreshVideo()
    refreshAdverts()
  }, []);

  useEffect(() => {
    if (minDate) {
      const max = dayjs(minDate).add(4, 'week').endOf('day').toDate()
      setMaxDate(max)
    }
  }, [minDate])

  const positionCallback = useCallback(({coords}: any) => {
    if (coords) {
      console.log(coords)
      const {latitude, longitude} = coords;
      const tmpVenues = [...venues]
      tmpVenues.forEach(venue => {
        venue.distanceInMetersFromMe = distanceInMeters(latitude, longitude, venue.position?.lat || 0, venue.position?.lng || 0)
      })
      setMyPosition({lat: latitude, lng: longitude})
    } else {
      setMyPosition(null)
    }
  }, [venues, setMyPosition])

  const updatePosition = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(positionCallback, (e) => {setMyPosition(null); console.error('getPositionFailed', e)}, {maximumAge: 15000});
    }
  }, [positionCallback, setMyPosition])

  return (
    <ApiDataContext.Provider
      value={{
        venues,
        refreshVenues,
        skimpies,
        refreshSkimpies,
        shifts,
        refreshShifts,
        getVenue,
        getSkimpy,
        getVenueShifts,
        getSkimpyShifts,
        updatePosition,
        myPosition,
        setMyPosition,
        video,
        refreshVideo,
        videoLoading,
        adverts,
        refreshAdverts,
        getAds,
        minDate,
        maxDate,
        venuesWithShifts,
        venuesWithDistances
      }}
    >
      {children}
    </ApiDataContext.Provider>
  );
};

export default ApiDataProvider;
