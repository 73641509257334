import React from 'react'
import AdBlock from './AdBlock'
import TabHeader from './TabHeader'

export function TabBarAdBlock() {
  return (
    <>
      <TabHeader />
      <AdBlock />
    </>
  )
}