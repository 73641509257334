import React, { useContext, useEffect, useLayoutEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import MapBlock from '../../../components/MapBlock';

import './VenueDetails.scss';
import { Venue } from '../../../models/Venues';
import ShiftBlock from './ShiftBlock';
import { ApiDataContext } from '../../../contexts/ApiDataContext';
import ContactActionButton from '../../../components/ContactActionButton';
import PerthsBestGirls from '../../../assets/pngs/perths_best_girls.png';
import { TabBarAdBlock } from '../../../components/TabBarAdBlock';
import { EnquirySubjects } from '../../../models/Email';
import { MapContext } from '../../../contexts/MapContext';
import { venuesToMarkers } from '../../../services/geoJson.svc';
import RatioDiv from '../../../components/RatioDiv';
import { AdsContext } from '../../../contexts/AdsContext';

const urlVenueAddress = (venue: Venue): String => {
  const streetParts = [
    venue?.streetNumber,
    venue?.street,
  ]
  const addrParts = [
    venue?.suburb,
    venue?.state,
  ]

  return encodeURIComponent([streetParts.filter(f => f).join(' '), ...addrParts.filter(f => f)].join(','))
}

const DirectionsLink = ({venue, children}: any) => {
  const isIos = useMemo(() => {
    const {platform} = (navigator as any).userAgentData ? (navigator as any).userAgentData : navigator
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }, [])

  return isIos 
    ? <a href={`https://maps.apple.com/?ll=${venue?.position?.lat},${venue?.position?.lng}`}>{children}</a>
    : <a href={`https://maps.google.com/?q=${venue?.position?.lat},${venue?.position?.lng}`}>{children}</a>
}

const VenueDetails = () => {
  const { setMarkers } = useContext(MapContext);
  const { getVenue, getVenueShifts } = useContext(ApiDataContext);
  const { id } = useParams<any>();
  const [venue, setVenue] = useState<Venue | null>();
  const [dates, setDates] = useState<string[]>();
  const [shiftMap, setShiftMap] = useState<{ [id: string]: any[] }>();
  const { setAdsVenueId } = useContext(AdsContext);

  useLayoutEffect(() => {}, []);

  useEffect(() => {
    id && setAdsVenueId && setAdsVenueId(+id);
  }, [setAdsVenueId, id]);

  useEffect(() => {
    if (!!id && !!getVenue && !!setVenue) {
      setVenue(getVenue(+id));
    }
  }, [id, getVenue, setVenue]);

  useEffect(() => {
    if (venue && getVenueShifts && setMarkers) {
      const [_dates, _shiftMap] = getVenueShifts(venue.venueId);
      setDates(_dates);
      setShiftMap(_shiftMap);
      setMarkers(venuesToMarkers([venue]));
    }
  }, [venue, getVenueShifts, setMarkers]);

  return (
    <div className="page-content venue-details-page">
      {!venue ? (
        []
      ) : (
        <>
          <TabBarAdBlock />
          <h2>{venue?.name}</h2>
          <div className="this-months-skimpies-from">This Month's Skimpies From</div>
          
          <a href={venue.brandUrl} rel="noreferrer" target="_blank" className="brand-image">
            {!!venue.brandImgPath ? (
              <img src={venue.brandImgPath} alt={venue.brandName || 'brand of skimpy'} />
            ) : (
              <img src={PerthsBestGirls} alt={"Perth's Best Girls"} />
            )}
          </a>
          {(dates || []).map((m) => (
            <ShiftBlock key={m} date={m} shifts={(shiftMap || {})[m] || []} />
          ))}
          <DirectionsLink venue={venue}>
            <RatioDiv className="map-block-container" ratio={0.6915}>
              <MapBlock options={{ zoom: 15 }} />
            </RatioDiv>
          </DirectionsLink>
        </>
      )}
      {venue?.name ? (
        <ContactActionButton
          text={[`Message us about`, venue.name]}
          to={`/contact-us?subject=${EnquirySubjects.MessageVenue}&extra=${venue.name}`}
        />
      ) : (
        []
      )}
    </div>
  );
};

export default VenueDetails;
