import './CallSkimpyLink.scss'

const CallSkimpyLink = () => {
  return (
    <a className="contact-link" href="tel:+61435754679">
      0435SKIMPY
    </a>
  );
};

export default CallSkimpyLink;
