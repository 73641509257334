import React, { useEffect, useState } from 'react';
import { PhoneIcon, SendIcon } from '../../components/icons';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { defaultSubject, Email } from '../../models/Email';
import { postEmailEnquiry } from '../../services/http.svc';
import SkimpySpinner from '../../components/SkimpySpinner';
import { useQuery } from '../../hooks/useQuery';
import { useHistory } from 'react-router-dom';

const errMsg = ({ message }: any) => <label>{message}</label>;

const ContactUs = () => {
  const { subject: subj, extra } = useQuery() as any;
  const { register, formState, getValues, handleSubmit, trigger, reset } = useForm<Email>({
    mode: 'onChange',
    defaultValues: { subject: defaultSubject(subj, extra) },
  });
  const { isValid, errors } = formState;
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const submit = (data: any, e: any) => {
    (async () => {
      await trigger();
      console.log('submit', data, isValid);
      if (isValid) {
        try {
          setLoading(true);
          const payload = getValues() as Email;
          await postEmailEnquiry(payload);
          reset();
          history.push('/confirmation');
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      }
    })();
  };

  const submitErr = (errors: any, e: any) => {
    console.error('submitErr', errors, e);
  };

  useEffect(() => {
    console.log(formState.errors, formState.dirtyFields);
  }, [formState]);

  return (
    <>
      <div className="form-page">
        <h2>Contact Us</h2>

        <a className="btn" href="tel:+61435754679">
          <PhoneIcon />
          0435 SKIMPY
        </a>
        <form onSubmit={handleSubmit(submit, submitErr)} noValidate>
          <label htmlFor="name">
            Your Name *<ErrorMessage name="name" errors={errors} render={errMsg} />
          </label>
          <input placeholder="Enter your name" {...register('name', { required: ' is required' })} />

          <label htmlFor="phone">
            Phone Number *<ErrorMessage name="phone" errors={errors} render={errMsg} />
          </label>
          <input
            type="tel"
            placeholder="+61..."
            {...register('phone', {
              required: ' is required',
              pattern: { value: /^[+]{0,1}[0-9]{8,10}$/, message: ' 8 to 10 digits required' },
            })}
          />

          <label htmlFor="email">
            Email Address *<ErrorMessage name="email" errors={errors} render={errMsg} />
          </label>
          <input
            type="email"
            placeholder="example@mail.com"
            {...register('email', {
              required: ' is required',
              pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, message: ' invalid format' },
            })}
          />

          <label htmlFor="subject">
            Subject *<ErrorMessage name="subject" errors={errors} render={errMsg} />
          </label>
          <input
            type="text"
            placeholder="What your enquiry is about..."
            {...register('subject', { required: ' is required' })}
          />

          <label htmlFor="comments">
            Comments *<ErrorMessage name="comments" errors={errors} render={errMsg} />
          </label>
          <textarea
            placeholder="Additional information to help us accurately quote you"
            {...register('comments', { required: ' more info needed' })}
          />
          <button type="submit" className="btn blue submit">
            <SendIcon />
            Send Enquiry
          </button>
        </form>
      </div>
      {!!loading && <SkimpySpinner />}
    </>
  );
};

export default ContactUs;
