import React from 'react';

export function CloseIcon() {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5247 9.125L18.6418 3.06445C18.9817 2.72461 18.9817 2.10156 18.6418 1.76172L17.2258 0.345703C16.886 0.00585938 16.2629 0.00585938 15.9231 0.345703L9.86255 6.46289L3.74536 0.345703C3.40552 0.00585938 2.78247 0.00585938 2.44263 0.345703L1.02661 1.76172C0.686768 2.10156 0.686768 2.72461 1.02661 3.06445L7.1438 9.125L1.02661 15.2422C0.686768 15.582 0.686768 16.2051 1.02661 16.5449L2.44263 17.9609C2.78247 18.3008 3.40552 18.3008 3.74536 17.9609L9.86255 11.8438L15.9231 17.9609C16.2629 18.3008 16.886 18.3008 17.2258 17.9609L18.6418 16.5449C18.9817 16.2051 18.9817 15.582 18.6418 15.2422L12.5247 9.125Z"
        fill="inherit"
      />
    </svg>
  );
}
