import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { NULL_SKIMPY } from './Skimpies';

dayjs.extend(advancedFormat);

export interface Shift {
  shiftId: number;
  venueId: number;
  venueName: string;
  venueSuburb: string;
  venueAddress: string;
  skimpyId: number | null;
  skimpyName: string;
  skimpyImage: string;
  start: Date;
  end: Date;
  startStr: string | null;
  startEnd: string;
  skimpyProfileEnabled: boolean;
}

const formattedTime = (date: Date): string => {
  const djs = dayjs(date);
  const minutes = Math.floor(djs.minute());
  return minutes !== 0 ? djs.format('h:mmA') : djs.format('hA');
};

const formattedStartEnd = (start: Date, end: Date): string => {
  return `${formattedTime(start)} - ${formattedTime(end)}`;
};

const toStartStr = (dateStr: string): string => dayjs(dateStr).startOf('day').format('dddd, Do MMMM')

const nameCleanup = (name: string): string => {
  const lastIndex = name.lastIndexOf('-');
  if (lastIndex > -1) {
    return name.substr(0, lastIndex).trim()
  }
  return name
}

export const ShiftMapper = (apiObj: any): Shift => {
  const tmp: Shift = {
    shiftId: apiObj.ShiftId,
    venueId: apiObj.VenueId,
    venueName: nameCleanup(apiObj.VenueName),
    venueSuburb: apiObj.VenueSuburb,
    venueAddress: apiObj.VenueAddress,
    skimpyId: apiObj.SkimpyId || NULL_SKIMPY.skimpyId,
    skimpyName: apiObj.SkimpyStageName || NULL_SKIMPY.stageName,
    skimpyImage: apiObj.SkimpyImagePath ? apiObj.SkimpyImagePath : '',
    skimpyProfileEnabled: !!apiObj.SkimpyProfileEnabled || false,
    start: new Date(apiObj.Start),
    end: new Date(apiObj.End),
    startStr: toStartStr(apiObj.Start),
    startEnd: formattedStartEnd(dayjs(apiObj.Start).toDate(), dayjs(apiObj.End).toDate()),
  }

  return tmp
}