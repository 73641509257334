import React, { useState, createContext, useEffect } from 'react';
import { buildGeoJson, GeoJson, MapMarkerType } from '../services/geoJson.svc';

type MapContextType = {
  geoJson: GeoJson | null;
  setMarkers: (m: MapMarkerType[]) => void;
  map: any;
  setMap: (m: any) => void;
  api: any;
  setApi: (m: any) => void;
};

export const MapContext = createContext<Partial<MapContextType>>({});

const MapProvider = ({ children }: any) => {
  const [markers, setMarkers] = useState<MapMarkerType[]>([]);
  const [geoJson, setGeoJson] = useState<GeoJson | null>(null);
  const [map, setMap] = useState<any>()
  const [api, setApi] = useState<any>()

  useEffect(() => {
    if (markers?.length) {
      const tmp = buildGeoJson(markers);
      setGeoJson(tmp);
    }
  }, [markers]);

  return <MapContext.Provider value={{ geoJson, setMarkers, map, setMap, api, setApi }}>{children}</MapContext.Provider>;
};

export default MapProvider;

