import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import ContactActionButton from '../../components/ContactActionButton';
import { ChevronRightIcon, CloseIcon, SearchIcon } from '../../components/icons';
import MapBlock from '../../components/MapBlock';
import { ApiDataContext } from '../../contexts/ApiDataContext';
import { MapContext } from '../../contexts/MapContext';
import { EnquirySubjects } from '../../models/Email';
import { Venue } from '../../models/Venues';
import { MapMarkerType, venuesToMarkers } from '../../services/geoJson.svc';
import { closestVenuesWithinKmRadius, distanceDisplay, distanceInMeters } from '../../services/location.svc';
import './Map.scss';

import RatioDiv from '../../components/RatioDiv';
import TabHeader from '../../components/TabHeader';
import { AdsContext } from '../../contexts/AdsContext';
import GeolocationListener from './GeolocationListener';
import { PlacesWrapper } from '../../services/placesWrapper.svc';

const locationFilter = (lat: number, lng: number, venues: Venue[]): Venue[] => {
    const minVenuesReturned = 10;
    const maxRadiusKms = 25;
    let radiusKms = 0;
    let filtered: Venue[] = [];
    while (radiusKms < maxRadiusKms && filtered.length < minVenuesReturned) {
        radiusKms += 5;
        filtered = closestVenuesWithinKmRadius({ lat, lng }, venues || [], radiusKms);
        console.log(`locationFilter ${radiusKms}km`, filtered);
    }
    return filtered;
};

const VenueLink = ({ venue, selectedPlace }: any) => {
    const [dist, setDist] = useState<string>('');
    useEffect(() => {
        if (!!venue && setDist) {
            setDist(distanceDisplay(venue.distanceInMetersFromPoint || 0));
        }
    }, [venue, setDist]);
    return (
        <Link className="venue-link" to={`/venues/${venue.venueId}`}>
            <label>{venue.name.toLowerCase()}</label>
            <label>{dist ? `${dist} ${selectedPlace?.name ? `from ${selectedPlace.name}` : 'away'}` : venue.suburb}</label>
            <ChevronRightIcon />
        </Link>
    );
};

const DefaultActionButton = () => {
    return <ContactActionButton text={['Book Skimpies Here']} to={'/book-skimpies'} />;
};

const LocationActionButton = (searchTerm: string) => {
    return <ContactActionButton text={['Message us about this location']} to={`/contact-us?subject=${EnquirySubjects.MessageLocation}&extra=${searchTerm}`} />;
};

const Map = () => {
    const { setMarkers, api, map } = useContext(MapContext);
    const { venuesWithDistances: venues, updatePosition, myPosition } = useContext(ApiDataContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [autocompleteResults, setAutocompleteResults] = useState<any[]>([]);
    const [selectedPlace, setSelectedPlace] = useState<any | null>(null);
    const [showAutocompleteResults, setShowAutocompleteResults] = useState(false)
    const { setAdsVenueId } = useContext(AdsContext);

    const svc = useMemo(() => {
        if (!!api && !!map) {
            return new PlacesWrapper(map, api);
        }
    }, [api, map]);

    const filteredVenues = useMemo(() => {
        if (!selectedPlace) {
            return [];
        }
        if (!venues || !venues.length) {
            return [];
        }
        const { lat, lng } = selectedPlace;
        const filtered = locationFilter(lat, lng, venues || []);
        filtered.forEach((f) => {
            if (f.position?.lat && f.position?.lng) {
                f.distanceInMetersFromPoint = distanceInMeters(lat, lng, f.position?.lat, f.position?.lng);
            }
        });
        return filtered.sort((a, b) => (a.distanceInMetersFromPoint || 0) - (b.distanceInMetersFromPoint || 0));
    }, [venues, selectedPlace]);

    useEffect(() => {
        setAdsVenueId && setAdsVenueId(new Date().getTime());
    }, [setAdsVenueId]);

    useEffect(() => {
        if (updatePosition && !myPosition) {
            updatePosition();
        }
    }, [updatePosition, myPosition]);

    useEffect(() => {
        if (setMarkers) {
            let tmp: MapMarkerType[] = [];
            if (venues?.length) {
                tmp = venuesToMarkers(venues);
            }
            if (myPosition) {
                tmp.unshift({ title: 'me', isCurrentLocation: true, isSelectedLocation: false, position: myPosition });
            }
            if (selectedPlace) {
                tmp.unshift({
                    title: selectedPlace.name,
                    isCurrentLocation: false,
                    isSelectedLocation: true,
                    position: { lat: selectedPlace.lat, lng: selectedPlace.lng },
                });
            }
            if (!myPosition && !selectedPlace) {
                tmp.unshift({
                    title: 'Perth',
                    isCurrentLocation: false,
                    isSelectedLocation: false,
                    isTransparentIcon: true,
                    position: { lat: -31.9523, lng: 115.8613 },
                });
            }
            setMarkers(tmp);
        }
    }, [venues, myPosition, selectedPlace, setMarkers]);

    useEffect(() => {
        if (!!searchTerm && !!svc) {
            (async () => {
                try {
                    const res: any[] = await svc.searchPlaces(searchTerm);
                    setAutocompleteResults(res || []);
                } catch (err) {
                    console.error(err);
                }
            })();
        } else if (!searchTerm) {
            setSelectedPlace(null);
            setAutocompleteResults([]);
        }
    }, [searchTerm, svc, venues, selectedPlace]);

    useEffect(() => {
        if (selectedPlace && setSearchTerm) {
            setSearchTerm(selectedPlace.name);
            setAutocompleteResults([])
        }
    }, [selectedPlace, setSearchTerm]);

    const handlePlaceSelect = async (place: any) => {
        if (svc) {
            try {
                const placeDetails = await svc.getPlace(place.placeId);
                const lat = placeDetails.geometry.location.lat();
                const lng = placeDetails.geometry.location.lng();
                setSelectedPlace({ ...place, lat, lng });
            } catch (err) {
                console.error(err);
                setSelectedPlace(null);
            }
            setShowAutocompleteResults(false)
            setAutocompleteResults([]);
        }
    };

    const onSearchTermChange = (evt:  React.ChangeEvent<HTMLInputElement>) => {
        const value = evt?.target?.value || ''
        setShowAutocompleteResults(value?.length > 0)
        setSearchTerm(value)
    }

    return (
        <div className="page-content map-page">
            <GeolocationListener />
            <TabHeader />

            <form autoComplete={'off'}>
                <label>
                    <input type="text" name="search" value={searchTerm} onChange={onSearchTermChange} />
                    {searchTerm ? (
                        <span onClick={() => setSearchTerm('')}>
                            <CloseIcon />
                        </span>
                    ) : (
                        <span>
                            <SearchIcon />
                        </span>
                    )}
                </label>
                {autocompleteResults?.length && searchTerm?.length && showAutocompleteResults ? (
                    <div className="search-list-wrapper">
                        {autocompleteResults.map((m) => (
                            <div onClick={() => handlePlaceSelect(m)} key={m.name} role="button">
                                {m.name}
                            </div>
                        ))}
                    </div>
                ) : (
                    []
                )}
            </form>
            <RatioDiv className="map-block-container" ratio={0.6915}>
                <MapBlock options={{ zoom: 15 }} />
            </RatioDiv>

            {selectedPlace && filteredVenues?.length
                ? filteredVenues.map((venue) => <VenueLink key={venue.venueId} venue={venue} selectedPlace={selectedPlace} />)
                : []}

            {searchTerm?.length ? LocationActionButton(searchTerm) : DefaultActionButton()}
        </div>
    );
};

export default Map;
