import React from 'react';

import AboutUsImage from '../../assets/pngs/about_us_img.jpg';
import CallSkimpyLink from '../../components/CallSkimpyLink';
import ContactActionButton from '../../components/ContactActionButton';


const AboutUs = () => {
    return (
        <div className="static-page static-text">
            <h2>About Us</h2>
            <img src={AboutUsImage} className="about-us-img" alt="girl with punters" />
            <p>
                Skimpy Heaven was founded in 2011 and is a thriving support hub for a huge network of freelance hospitality entertainers. We are often asked how
                many girls we have: The answer is hundreds, and growing every day.
            </p>
            <p>
                Our bookings website assists a group of co-operative adult entertainment brands to reliably service a large number of taverns in Western
                Australia, from CBD and metropolitan to small country or remote towns.
            </p>
            <p>
                We provide skimpy barmaids, sports girls, lingerie waitresses and exotic dance performers to many dozens of WA venues. We can also assist hotels
                with well presented fully-clothed bar staff for special events or temporary contracts with our affiliated brand{' '}
                <a href="http://www.guestbarstaff.com.au">www.guestbarstaff.com.au</a>.
            </p>
            <p>
                If you have a venue and would like to use Skimpy Heaven talent to attract more patrons and keep them there, we would love to talk about how we
                can help you.
            </p>
            <p>
                <strong>
                    Call <CallSkimpyLink /> now.
                </strong>
            </p>
            <p>
                Our founder and managing director brings over 25 years of industry experience, combined with an innovative business model that has vastly
                improved customer and contractor experiences since 2011. We will also only work with adult entertainment brand owners who support freelance
                rights for workers and promote an abuse-free, drug-free work environment.
            </p>
            <p>
                The proof of our success is in our great reputation with over 100 venues, built by our unwavering dedication for over a decade to revolutionize
                the quality of skimpy services in Western Australia. We maintain the utmost highest standards in venue entertainment to make sure we exceed
                customer expectations while complying with WA legislation. Our strict quality control processes ensure through a series of checks and measures
                that there is a suitable worker showing up at every single order, or compensation will be offered.
            </p>
            <p>
                While female staff for pubs and parties are our primary business, we can also book relief bar staff, promo staff, male ‘himpies’, Ladies Nights,
                DJs, our Fatogram and other entertainment services, but Skimpy Heaven provides strictly NO escort, pornography, or anything of that nature.
            </p>
            <p>
                Our staffed headquarters in Osborne Park has its own skimpy costume shop and dance studio for training, and is also available for party
                warehouse hires.
            </p>
            <p>
                Please call us on <CallSkimpyLink /> or email{' '}
                <a className="contact-link" href={`mailto:admin@skimpyheaven.com.au`}>
                    admin@skimpyheaven.com.au
                </a>{' '}
                to enquire about anything at all. We would be so happy to hear from you!
            </p>
            <h3>OUR VENUES SAY...</h3>
            <q>
                We love what you’ve done with Skimpy Heaven. It’s a breath of fresh air. Finally a skimpy company that understands things from the pub owner’s
                perspective. You have a truly great team of girls. The website login with their RSAs and photos is exactly what we need too. Well done, we are
                very happy..
            </q>

            <ContactActionButton text={['Book Skimpies Here']} to={'/book-skimpies'} />
        </div>
    );
};

export default AboutUs;
