import React, { useLayoutEffect, useRef, useState } from 'react';

const RatioDiv = ({ ratio, children, ...rest }: any) => {
  const [height, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    if (ref && ref.current && setHeight && ratio) {
      const rect = ref.current.getBoundingClientRect()
      setHeight(ratio * rect.width)
    }
  }, [ref, ratio])

  return <div ref={ref} style={{height}} {...rest}>{children}</div>
};

export default RatioDiv;
