import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  VenuesIcon,
  SkimpiesIcon,
  DateIcon,
  LocationIcon,
  PlayIcon,
  PauseIcon,
  VolumeMuteIcon,
  VolumeUnmuteIcon,
} from '../../components/icons';
import MainBrandImage from '../../assets/pngs/skh_logo_text.png';

import './Home.scss';
import ContactActionButton from '../../components/ContactActionButton';
import FireFlies from '../../components/FireFlies';
import { ApiDataContext } from '../../contexts/ApiDataContext';

const navLinks = [
  { to: '/venues', text: 'Venues', icon: VenuesIcon },
  { to: '/skimpies', text: 'Skimpies', icon: SkimpiesIcon },
  { to: '/date', text: 'Date', icon: DateIcon },
  { to: '/map', text: 'Map', icon: LocationIcon },
];

const VIDEO_STATE_PLAYING = 0;
const VIDEO_STATE_PAUSED = 1;
const VIDEO_STATE_MUTED = 2;
const VIDEO_STATE_UNMUTED = 3;
const VIDEO_STATE_UNKNOWN = -1;

const NavTab = ({ item }: any) => {
  return (
    <Link className="home-nav-btn" to={item.to}>
      {item.icon()}
      <label>{item.text}</label>
    </Link>
  );
};

const Home = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null);
  const brandRef = useRef<HTMLDivElement | null>(null);
  const [adHeight, setAdHeight] = useState<number>(0);
  const [brandHeight, setBrandHeight] = useState<number>(0);
  const { video, videoLoading } = useContext(ApiDataContext);
  const [videoPlayingState, setVideoPlayingState] = useState<number>(VIDEO_STATE_UNKNOWN);
  const [videoMutedState, setVideoMutedState] = useState<number>(VIDEO_STATE_MUTED);

  useLayoutEffect(() => {
    if (!!ref && !!ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setAdHeight((rect.width / 16) * 9);
    }
  }, [ref]);

  useLayoutEffect(() => {
    if (!!brandRef && !!brandRef.current) {
      const rect = brandRef.current.getBoundingClientRect();
      setBrandHeight(rect.width * 0.416);
    }
  }, [brandRef]);

  useEffect(() => {
    if (!!videoRef) {
      videoRef.addEventListener('play', (e) => {
        setVideoPlayingState(VIDEO_STATE_PLAYING);
      });
      videoRef.addEventListener('pause', (e) => {
        setVideoPlayingState(VIDEO_STATE_PAUSED);
      });
      videoRef.loop = false
    }
  }, [videoRef]);

  const togglePlay = () => {
    if (!!videoRef) {
      videoPlayingState === VIDEO_STATE_PLAYING ? videoRef.pause() : videoRef.play();
      setVideoPlayingState(videoPlayingState === VIDEO_STATE_PLAYING ? VIDEO_STATE_PAUSED : VIDEO_STATE_PLAYING)
    }
  };

  const toggleMuted = () => {
    setVideoMutedState(videoMutedState === VIDEO_STATE_MUTED ? VIDEO_STATE_UNMUTED : VIDEO_STATE_MUTED);
  };

  return (
    <>
      <div className="home-wrapper">
        <div className="main-image" ref={brandRef} style={{ height: brandHeight }}>
          <FireFlies count={45} position={'absolute'} />
          <img src={MainBrandImage} alt="Skimpy Heaven brand" />
        </div>

        <div ref={ref} style={{ height: `auto`, position: 'relative' }} className="skhad-container">
          {!!videoLoading ? <label>loading video</label> : []}
          {videoLoading === false && !video ? (
            <iframe
              style={{ backgroundColor: 'transparent', borderRadius: '0.75em' }}
              width="100%"
              height={`${adHeight}px`}
              src="https://www.youtube.com/embed/tsrN2_S5v2U"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            []
          )}
          {videoLoading === false && !!video ? (
            <>
              <video
                ref={(vr) => setVideoRef(vr)}
                style={{ width: '100%', position: 'relative' }}
                src={video.path}
                playsInline={true}
                autoPlay={true}
                muted={videoMutedState === VIDEO_STATE_MUTED}
              />
              <div className="video-controls">
                <div role="button" className="cntrl" onClick={() => toggleMuted()}>
                  {videoMutedState === VIDEO_STATE_MUTED ? <VolumeUnmuteIcon /> : <VolumeMuteIcon />}
                </div>
                <div role="button" className="cntrl" onClick={() => togglePlay()}>
                  {videoPlayingState === VIDEO_STATE_PLAYING ? <PauseIcon /> : <PlayIcon />}
                </div>
              </div>
            </>
          ) : (
            []
          )}
        </div>
        {navLinks.map((m) => (
          <NavTab key={m.text} item={m} />
        ))}
      <ContactActionButton text={['Get in touch']} to={'/contact-us'} />
      </div>
    </>
  );
};

export default Home;
