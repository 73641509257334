import React from 'react';

export function HamburgerMenuIcon() {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.6875 3.42188H18.5625C18.9062 3.42188 19.25 3.12109 19.25 2.73438V1.01562C19.25 0.671875 18.9062 0.328125 18.5625 0.328125H0.6875C0.300781 0.328125 0 0.671875 0 1.01562V2.73438C0 3.12109 0.300781 3.42188 0.6875 3.42188ZM0.6875 10.2969H18.5625C18.9062 10.2969 19.25 9.99609 19.25 9.60938V7.89062C19.25 7.54688 18.9062 7.20312 18.5625 7.20312H0.6875C0.300781 7.20312 0 7.54688 0 7.89062V9.60938C0 9.99609 0.300781 10.2969 0.6875 10.2969ZM0.6875 17.1719H18.5625C18.9062 17.1719 19.25 16.8711 19.25 16.4844V14.7656C19.25 14.4219 18.9062 14.0781 18.5625 14.0781H0.6875C0.300781 14.0781 0 14.4219 0 14.7656V16.4844C0 16.8711 0.300781 17.1719 0.6875 17.1719Z"
        fill="inherit"
      />
    </svg>
  );
}
