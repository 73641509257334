import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  DateIcon,
  LocationIcon,
  SkimpiesIcon,
  VenuesIcon
} from './icons';

import './TabHeader.scss';

const TabIcon = ({ to, label, icon }: any) => {
  const {pathname} = useLocation()
  return (
    <Link className={pathname.endsWith(to) ? 'active' : ''} to={to} >
      {icon()}
      <label>{label}</label>
    </Link>
  );
};

const TabHeader = () => {

  return (
    <div className="tab-header-wrapper">
      <TabIcon
        to={'/venues'}
        label={'Venues'}
        icon={VenuesIcon}
        />
      <TabIcon
        to={'/skimpies'}
        label={'Skimpies'}
        icon={SkimpiesIcon}
      />
      <TabIcon
        to={'/date'}
        label={'Date'}
        icon={DateIcon}
      />
      <TabIcon
        to={'/map'}
        label={'Map'}
        icon={LocationIcon}
      />

    </div>
  );
};

export default TabHeader;
