import React from 'react'

import Terms from '../pdfs/TermsAndConditions.pdf'; 
import Privacy from '../pdfs/PrivacyPolicy.pdf'

import './Footer.scss'
import {BUILD_NUMBER, APP_ENV} from '../config' 
 const year = (new Date()).getFullYear()
const buildNum = `1.0.${BUILD_NUMBER} ${APP_ENV}`

const Footer = () => {

  return (
      <footer className="footer-wrapper">
        <a href="/advertising">Advertising</a>
        <a href={Terms} target="_blank" rel="noreferrer">Terms & Conditions</a>
        <a href={Privacy} target="_blank" rel="noreferrer">Privacy Statement</a>
        <p>
          <span>
            &#169; Copyright {year}
          </span>
          <span>
            Version: {buildNum}
          </span>
        </p>
      </footer>
  )
}

export default Footer