import React from 'react';
import { Link } from 'react-router-dom';
import SkimpyPreview from '../../../components/SkimpyPreview';
import { Shift } from '../../../models/Shifts';
import { NULL_SKIMPY } from '../../../models/Skimpies';
import './ShiftForDate.scss';

type ShiftForDateType = {
  shift: Shift;
};

const ShiftForDate = ({ shift }: ShiftForDateType) => {
  return !!shift ? (
    <div className="shift-for-date-row">
      <Link className="venue" to={`/venues/${shift.venueId}`}>
        <label>{shift.venueName}</label>
      </Link>
      {!!shift.skimpyProfileEnabled ? (
        <Link className="skimpy-img" to={`/skimpies/${shift.skimpyId}`}>
          <SkimpyPreview skimpyImage={shift.skimpyImage} skimpyName={shift.skimpyName} />
        </Link>
      ) : (
        <SkimpyPreview skimpyImage={shift.skimpyImage} skimpyName={shift.skimpyName} />
      )}
      {!!shift.skimpyProfileEnabled ? (
        <Link to={`/skimpies/${shift.skimpyId}`} className="skimpy-lnk">
          <label>{shift.skimpyName || NULL_SKIMPY.stageName}</label>
          <label>{shift.startEnd}</label>
        </Link>
      ) : (
        <div>
          <label>{shift.skimpyName || NULL_SKIMPY.stageName}</label>
          <label>{shift.startEnd}</label>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default ShiftForDate;
