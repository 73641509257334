import { Venue } from "../models/Venues";
import LocIcon from '../assets/svgs/location_icon_red.svg'
import LocIconTransparent from '../assets/svgs/location_icon_transparent.svg'

const buildIcon = (marker: MapMarkerType): string | any => {
  if (marker.isCurrentLocation) {
    return {
      url: '/assets/img/current_loc.png',
      origin: { x: 0, y: 0 },
      anchor: { x: 10, y: 10 },
    }
  }
  if (marker.isSelectedLocation || marker.isTransparentIcon) {
    return LocIconTransparent
  }
  return LocIcon
};

export type MapMarkerType = {
  title: string;
  isCurrentLocation: boolean;
  isSelectedLocation: boolean;
  isTransparentIcon?: boolean;
  position: { lat: number; lng: number };
  venueId?: number;
};

const buildFeature = (marker: MapMarkerType) => {
  const feature = {
    type: 'Feature',
    properties: {
      title: marker.title,
      icon: buildIcon(marker),
      venueId: marker.venueId
    },
    geometry: {
      type: 'Point',
      coordinates: [marker.position.lng, marker.position.lat],
    },
  };
  if (!marker.venueId) {
    delete feature.properties.venueId
  }
  return feature
};

type Feature = {
  type: string;
  properties: { title: string; icon: string };
  geometry: { type: string; coordinates: number[] };
};

export type GeoJson = {
  type: string;
  features: Feature[];
};

export const buildGeoJson = (markers: MapMarkerType[]): GeoJson => {
  const collection = {
    type: 'FeatureCollection',
    features: [],
  } as GeoJson;

  markers.forEach((marker) => {
    const tmp = buildFeature(marker);
    collection.features.push(tmp);
  });

  return collection;
};

export const venuesToMarkers = (venues: Venue[]): MapMarkerType[] => {
  return venues.filter((v) => !!v.position)
  .map((venue) => ({ title: venue.name, isCurrentLocation: false, isSelectedLocation: false, position: venue.position, venueId: venue.venueId } as MapMarkerType));
}