import React from 'react'
import './CallMeSoon.scss'

const CallMeSoon = () => {
  return (
    <div className="call-me-soon">
      <div>My bookings are updated regularly.</div>
      <div>Visit me again soon xx</div>
    </div>
  );
};

export default CallMeSoon