export interface Advert {
  id: number;
  client_id: number;
  raw_path: string;
  sas_path: string;
  hyperlink: string;
  alt_text: string;
  comments: string;
  expires_at?: Date;
  is_active: boolean;
}

export const AdvertMapper = (apiObj: any) => {
  return apiObj as Advert;
}
