import React from 'react';

import { Router, Switch, Route, useRouteMatch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import './App.scss';
import Header from './components/Header';
import ApiDataProvider from './contexts/ApiDataContext';
import MapProvider from './contexts/MapContext';
import AboutUs from './pages/AboutUs/AboutUs';
import Advertising from './pages/Advertising/Advertising';
import BookSkimpy from './pages/BookSkimpy/BookSkimpy';
import Confirmation from './pages/Confirmation/Confirmation';
import ContactUs from './pages/ContactUs/ContactUs';
import Date from './pages/Date/Date';
import DateDetails from './pages/Date/DateDetails/DateDetails';

// routes
import Home from './pages/Home/Home';
import Map from './pages/Map/Map';
import Skimpies from './pages/Skimpies/Skimpies';
import SkimpyDetails from './pages/Skimpies/SkimpyDetails/SkimpyDetails';
import VenueDetails from './pages/Venues/VenueDetails/VenueDetails';
import Venues from './pages/Venues/Venues';
import WorkWithUs from './pages/WorkWihUs/WorkWithUs';

import * as Analytics from './services/analytics.svc';
import FireFlies from './components/FireFlies';
import AdsProvider from './contexts/AdsContext';

const VenueRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Venues />
      </Route>
      <Route path={`/venues/:id`}>
        <VenueDetails />
      </Route>
    </Switch>
  );
};
const SkimpyRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Skimpies />
      </Route>
      <Route path={`${path}/:id`}>
        <SkimpyDetails />
      </Route>
    </Switch>
  );
};
const DateRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Date />
      </Route>
      <Route path={`${path}/:id`} render={(routeProps) => <DateDetails {...routeProps} />}>
        <DateDetails />
      </Route>
    </Switch>
  );
};

const history = createBrowserHistory();
history.listen((location, action) => {
  Analytics.logPageView(location);
});

function App() {
  return (
    <ApiDataProvider>
      <AdsProvider>
        <MapProvider>
          <Router history={history}>
            <FireFlies count={60} />
            <div className="page">
              <Header />
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/venues">
                  <VenueRoutes />
                </Route>
                <Route path="/skimpies">
                  <SkimpyRoutes />
                </Route>
                <Route path="/map">
                  <Map />
                </Route>
                <Route path="/date">
                  <DateRoutes />
                </Route>
                <Route path="/about-us">
                  <AboutUs />
                </Route>
                <Route path="/advertising">
                  <Advertising />
                </Route>
                <Route path="/book-skimpies">
                  <BookSkimpy />
                </Route>
                <Route path="/confirmation">
                  <Confirmation />
                </Route>
                <Route path="/contact-us">
                  <ContactUs />
                </Route>
                <Route path="/work-with-us">
                  <WorkWithUs />
                </Route>
              </Switch>
              <div id="sticky-action"></div>
            </div>
          </Router>
        </MapProvider>
      </AdsProvider>
    </ApiDataProvider>
  );
}

export default App;
