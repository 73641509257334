export interface Email {
  name: string;
  phone: string;
  email: string;
  comments: string;
  subject: string;
  extras: string[];
}


export enum EnquirySubjects {
  Advertising = 'advertising',
  WorkWithUs = 'work_with_us',
  BookSkimpies = 'book_skimpies',
  MessageVenue = 'message_venue',
  MessageSkimpy = 'message_skimpy',
  MessageDate = 'message_date',
  MessageLocation = 'message_location'
}


const SKH_ENQUIRY_PREFIX = 'Skimpy Heaven Enquiry';
export const defaultSubject = (subject: EnquirySubjects | null, extra: string | null = null): string => {
  switch (subject) {
    case EnquirySubjects.Advertising:
      return `${SKH_ENQUIRY_PREFIX} - Advertising`;
    case EnquirySubjects.WorkWithUs:
      return `${SKH_ENQUIRY_PREFIX} - Work with us`;
    case EnquirySubjects.BookSkimpies:
      return `${SKH_ENQUIRY_PREFIX} - Book Skimpies`;
    case EnquirySubjects.MessageVenue:
      return `${SKH_ENQUIRY_PREFIX} - Venue${!!extra ? ` - ${extra}` : ''}`;
    case EnquirySubjects.MessageSkimpy:
      return `${SKH_ENQUIRY_PREFIX} - Skimpy${!!extra ? ` - ${extra}` : ''}`;
    case EnquirySubjects.MessageDate:
      return `${SKH_ENQUIRY_PREFIX} - Date${!!extra ? ` - ${extra}` : ''}`;
    case EnquirySubjects.MessageLocation:
      return `${SKH_ENQUIRY_PREFIX} - Location${!!extra ? ` - ${extra}` : ''}`;
    default:
      return `${SKH_ENQUIRY_PREFIX} - General Enquiry`;
  }
};
