export interface Venue {
    venueId: number;
    name: string;
    phone: string;
    streetNumber: string;
    street: string;
    suburb: string;
    state: string;
    position: { lat: number; lng: number } | null;
    distanceInMetersFromMe: number | null;
    distanceInMetersFromPoint?: number;
    address: string;
    brandImgPath: string;
    brandName: string;
    brandUrl: string;
}

const nameCleanup = (name: string): string => {
    const lastIndex = name.lastIndexOf('-');
    if (lastIndex > -1) {
        return name.substr(0, lastIndex).trim();
    }
    return name;
};

export const VenueMapper = (apiObj: any): Venue => {
    const tmp: any = {};
    tmp.venueId = apiObj.VenueId;
    tmp.name = nameCleanup(apiObj.Name); // TODO: get SKH to clean up client names to not include Suburb/Town name
    tmp.phone = apiObj.Phone;
    tmp.streetNumber = apiObj.StreetNumber;
    tmp.street = apiObj.Street;
    tmp.suburb = apiObj.Suburb;
    tmp.state = apiObj.State;
    tmp.address = `${apiObj.StreetNumber} ${apiObj.Street} ${apiObj.Suburb} ${apiObj.State}`;
    tmp.position = { lat: apiObj.Lat || 0, lng: apiObj.Lng || 0 };
    tmp.distanceInMeters = 0;
    tmp.brandImgPath = apiObj.BrandImagePath;
    tmp.brandName = apiObj.BrandName;
    tmp.brandUrl = apiObj.BrandUrl;
    return tmp as Venue;
};
