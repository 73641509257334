import React from 'react'
import InlineFlatpickr from './InlineFlatpickr'
import './ModalFlatpickr.scss'


const ModalFlatpickr = ({value, onValueChanged, options, onClose}) => {

  return (
    <div className="modal-flatpickr-wrapper">
      <div className="on-close-wrapper" onClick={() => onClose && onClose()}></div>
      <InlineFlatpickr {...{value, onValueChanged, options}} />
    </div>
  )
}

export default ModalFlatpickr
