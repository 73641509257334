import React from 'react';

export function SortDescendingIcon() {
  return (
    <svg width="15" height="13" viewBox="0 0 15 13" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.54688 5H12.7031C13.0039 5 13.25 4.78125 13.25 4.45312V1.17188C13.25 0.871094 13.0039 0.625 12.7031 0.625H8.54688C8.21875 0.625 8 0.871094 8 1.17188V4.45312C8 4.78125 8.21875 5 8.54688 5ZM14.2344 6.75H8.76562C8.32812 6.75 8 7.10547 8 7.51562V12.1094C8 12.5469 8.32812 12.875 8.76562 12.875H14.2344C14.6445 12.875 15 12.5469 15 12.1094V7.51562C15 7.10547 14.6445 6.75 14.2344 6.75ZM3.92578 0.761719C3.84375 0.707031 3.70703 0.652344 3.625 0.652344C3.51562 0.652344 3.37891 0.707031 3.29688 0.761719L1.10938 3.38672C0.835938 3.66016 1.02734 4.125 1.4375 4.125H2.75V12.4375C2.75 12.6836 2.94141 12.875 3.1875 12.875H4.0625C4.28125 12.875 4.5 12.6836 4.5 12.4375V4.125H5.8125C6.19531 4.125 6.38672 3.66016 6.11328 3.38672L3.92578 0.761719Z"
        fill="inherit"
      />
    </svg>
  );
}

export function SortAscendingIcon() {
  return (
    <svg width="15" height="13" viewBox="0 0 15 13" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.51953 5H12.7031C13.0039 5 13.25 4.75391 13.25 4.45312V1.17188C13.25 0.898438 13.0039 0.652344 12.7031 0.625H8.51953C8.21875 0.652344 8 0.898438 8 1.17188V4.45312C8 4.75391 8.21875 5 8.51953 5ZM14.2344 6.75H8.76562C8.32812 6.75 8 7.10547 8 7.51562V12.1094C8 12.5469 8.32812 12.875 8.76562 12.875H14.2344C14.6445 12.875 15 12.5469 15 12.1094V7.51562C15 7.10547 14.6445 6.75 14.2344 6.75ZM5.8125 9.375H4.5V1.0625C4.5 0.84375 4.28125 0.625 4.0625 0.625H3.1875C2.94141 0.625 2.75 0.84375 2.75 1.0625V9.375H1.4375C1.02734 9.375 0.835938 9.86719 1.10938 10.1406L3.29688 12.7656C3.37891 12.8203 3.51562 12.875 3.625 12.875C3.70703 12.875 3.84375 12.8203 3.92578 12.7656L6.11328 10.1406C6.38672 9.86719 6.19531 9.375 5.8125 9.375Z"
        fill="inherit"
      />
    </svg>
  );
}
