import {API_URL} from '../config'
import { AdvertMapper } from '../models/Advert'
import { Email } from '../models/Email'
import { ShiftMapper } from '../models/Shifts'
import { SkimpyMapper } from '../models/Skimpies'
import { VenueMapper } from '../models/Venues'
import { VideoMapper } from '../models/Video'

const VENUES_URL = `${API_URL}venues`
const SKIMPIES_URL = `${API_URL}skimpies`
const SHIFTS_URL = `${API_URL}shifts`
const ENQUIRY_URL = `${API_URL}enquiry`
const VIDEO_URL = `${API_URL}video`
const ADVERTS_URL = `${API_URL}content`



export const fetchVenues = async () => {
  try {
    const response = await fetch(VENUES_URL, 
      // {cache: "reload"}
      )
    if (!response.ok) {
      throw new Error(`HTTP-Error: ${response.status}`)
    }
    const data = await response.json()
    return (data || []).map(VenueMapper)
  } catch (err) {
    throw(err);
  }
}

export const fetchSkimpies = async () => {
  try {
    const response = await fetch(SKIMPIES_URL, 
      // {cache: "reload"}
      )
    if (!response.ok) {
      throw new Error(`HTTP-Error: ${response.status}`)
    }
    const data = await response.json()
    return (data || []).map(SkimpyMapper)
  } catch (err) {
    throw(err);
  }
}

export const fetchShifts = async (start: Date, end: Date) => {
  try {
    const response = await fetch(SHIFTS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      // cache: "reload",
      body: JSON.stringify({from: start.toISOString(), to: end.toISOString()})
    })
    if (!response.ok) {
      throw new Error(`HTTP-Error: ${response.status}`)
    }
    const data = await response.json()
    return (data || []).map(ShiftMapper)
  } catch (err) {
    throw(err);
  }
}

export const postEmailEnquiry = async (body: Email) => {
  try {
    const response = await fetch(ENQUIRY_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(body)
    })
    if (!response.ok) {
      throw new Error(`HTTP-Error: ${response.status}`)
    }
    return response.status
  } catch (err) {
    throw(err);
  }
}

export const fetchVideo = async () => {
  try {
    const response = await fetch(VIDEO_URL)
    if (!response.ok) {
      throw new Error(`HTTP-Error: ${response.status}`)
    }
    const data = await response.json()
    return VideoMapper(data)
  } catch(err) {
    throw(err)
  }
}

export const fetchAdverts = async () => {
  try {
    const response = await fetch(ADVERTS_URL)
    if (!response.ok) {
      throw new Error(`HTTP-Error: ${response.status}`)
    }
    const data = await response.json()
    return (data || []).map(AdvertMapper)
  } catch (err) {
    throw(err);
  }
}