
export const PERMISSION_DENIED = 1
export const POSITION_UNAVAILABLE = 2
export const TIMEOUT = 3

export const GEOLOCATION_STATUS_UPDATE = 'GEOLOCATION_STATUS_UPDATE'

const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 5000
}

const makeErrorHuman = (err: any) => {
    switch(err.code) {
        case PERMISSION_DENIED: return 'Permissions denied';
        default: return 'Unable to obtain position at this time'
    }
}

export const getLocationAsync = async () => {
    const promise = new Promise(async (resolve, reject) => {
        navigator.geolocation.getCurrentPosition(loc => {
            window.dispatchEvent(new CustomEvent(GEOLOCATION_STATUS_UPDATE, {detail: {enabled: true}}))
            resolve(loc)
            stopPolling()
        }, err => {
            window.dispatchEvent(new CustomEvent(GEOLOCATION_STATUS_UPDATE, {detail: {enabled: false}}))
            reject(makeErrorHuman(err))
        }, options)
    })
    return promise
}

let interval: any = null
export const startPolling = () => {
    console.log('Location Svc setting up geolocation availability interval check...')
    interval = setInterval(async () => {
        await getLocationAsync()
    }, 15000);
    (async() => {
        await getLocationAsync()
    })()
}

export const stopPolling = () => {
    console.log('Location Svc tearing down geolocation availability interval check...')
    if (interval) {
        clearInterval(interval)
    }
    
}