
export interface Skimpy {
  profileId: number;
  skimpyId: number;
  stageName: string;
  imagePath: string | null;
  profileEnabled: boolean;
}

export const NULL_SKIMPY: Skimpy = {
  profileId: -1,
  skimpyId: -1,
  stageName: 'CHECK BACK SOON',
  imagePath: null,
  profileEnabled: false
} 


export const SkimpyMapper = (apiObj: any): Skimpy => {
  const tmp: any = {}
  tmp.profileId = apiObj.ProfileId
  tmp.skimpyId = apiObj.SkimpyId
  tmp.stageName = apiObj.StageName
  tmp.imagePath = !!apiObj.ImagePath ? apiObj.ImagePath : null
  tmp.profileEnabled = !!apiObj.ProfileEnabled || false
  return tmp as Skimpy
}