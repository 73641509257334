import React from 'react';

export function LocationIcon() {
  return (
    <svg
      width="21"
      height="28"
      viewBox="0 0 21 28"
      fill="inherit"
      fillOpacity="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.40625 27.4263C9.89844 28.1912 11.0469 28.1912 11.5391 27.4263C19.5234 15.9532 21 14.7512 21 10.4898C21 4.69854 16.2969 0 10.5 0C4.64844 0 0 4.69854 0 10.4898C0 14.7512 1.42188 15.9532 9.40625 27.4263ZM10.5 14.8605C8.03906 14.8605 6.125 12.9483 6.125 10.4898C6.125 8.08585 8.03906 6.11902 10.5 6.11902C12.9062 6.11902 14.875 8.08585 14.875 10.4898C14.875 12.9483 12.9062 14.8605 10.5 14.8605Z"
        fill="inherit"
        fillOpacity="inherit"
      />
    </svg>
  );
}

export function LocationGlowIcon() {
  return (
    <svg width="33" height="42" viewBox="0 0 33 42" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path
          d="M15.3021 36.3444C15.8411 37.2185 17.099 37.2185 17.638 36.3444C26.3828 23.2322 28 21.8585 28 16.9883C28 10.3698 22.849 5 16.5 5C10.0911 5 5 10.3698 5 16.9883C5 21.8585 6.55729 23.2322 15.3021 36.3444ZM16.5 21.9834C13.8047 21.9834 11.7083 19.798 11.7083 16.9883C11.7083 14.241 13.8047 11.9932 16.5 11.9932C19.1354 11.9932 21.2917 14.241 21.2917 16.9883C21.2917 19.798 19.1354 21.9834 16.5 21.9834Z"
          fill="inherit"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="33"
          height="42"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.698039 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
