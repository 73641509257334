import React from 'react'
import {Shift} from '../../../models/Shifts';

import './ShiftBlock.scss'
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '../../../components/icons';


type SkimpyShiftBlockType = {
  date: string;
  shifts: Shift[];
}


const ShiftRow = ({shift}: any) => {

  return (
    <Link className="skimpy-shift-block-row" to={`/venues/${shift.venueId}`}>
      <label>{shift.venueName}</label>
      <label>{shift.startEnd}</label>
      <ChevronRightIcon />
    </Link>
  )
}


const ShiftBlock = ({date, shifts}: SkimpyShiftBlockType) => {

  return (
    <div className="skimpy-shift-block">
      <h3>{date}</h3>
      {!!shifts && shifts.length ? shifts.map(m => <ShiftRow shift={m} key={m.shiftId} />) : []}
    </div>
  )
}

export default ShiftBlock