import React from 'react';
import { Shift } from '../../../models/Shifts';
import {NULL_SKIMPY} from '../../../models/Skimpies'
import './ShiftBlock.scss';
import { Link } from 'react-router-dom';
import SkimpyPreview from '../../../components/SkimpyPreview';

type ShiftRowType = {shift: Shift}

const Wrapper = ({skimpyId, skimpyProfileEnabeld, children}: any) => {
  return !!skimpyProfileEnabeld ? (
    <Link className="venue-shift-row" to={`/skimpies/${skimpyId}`}>
      {children}
    </Link>
  ) : (
    <div className="venue-shift-row">
      {children}
    </div>
  )
}

const ShiftRow = ({ shift }: ShiftRowType) => {
  if (!shift) {
    return null
  }
  const {skimpyId, skimpyProfileEnabled: skimpyProfileEnabeld, skimpyName, skimpyImage, startEnd} = shift;
  return (skimpyId || 0) > 0 ? (
    <Wrapper {...{skimpyId, skimpyProfileEnabeld}}>
      <label>{skimpyName}</label>
      <label>{startEnd}</label>
      <SkimpyPreview skimpyName={skimpyName} skimpyImage={skimpyImage} />
    </Wrapper>  
  ) : (
    <div className="venue-shift-row">
      <label>{NULL_SKIMPY.stageName}</label>
      <label>{startEnd}</label>
      <SkimpyPreview skimpyName={NULL_SKIMPY.stageName} skimpyImage={''} />
    </div>
  );
};

const ShiftBlock = ({ date, shifts }: any) => {
  return (
    <>
      <h3 className="venue-shift-block-header">{date}</h3>
      {shifts.map((shift: Shift, i: number) => (
        <ShiftRow shift={shift} key={`${shift.shiftId}`} />
      ))}
    </>
  );
};

export default ShiftBlock;
