import React, { useLayoutEffect, useState } from 'react'
import './Fireflies.scss'
// https://www.csscodelab.com/html-css-only-tranquil-fireflies-effect-on-background/

const FireFlies = ({count, position}: any) =>  {
  const [fireFlies, setFireFlies] = useState<number[]>([])
  useLayoutEffect(() => {
    if (!!count && fireFlies && fireFlies.length !== count) {
      const tmp: number[] = []
      for(let i = 0; i < count; i++) {
        tmp.push(i)
      }
      setFireFlies(tmp)
    }
  }, [count, fireFlies])

  return <div className={`fireflies ${position || ''}`}>{fireFlies.map(m => <div className="firefly" key={`firefly-${m}`}></div>)}</div>
}

export default FireFlies