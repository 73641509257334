import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Skimpy } from '../models/Skimpies';
import { Venue } from '../models/Venues';
import { ChevronRightIcon, CloseIcon, SearchIcon } from './icons';
import { useHistory } from 'react-router-dom';
import Teleporter from './Teleporter';

import './SearchList.scss';

const SearchList = ({ skimpies, venues, onClose }: any) => {
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [links, setLinks] = useState<any[]>([]);
  const [filter, setFilter] = useState('');
  const [coords, setCoords] = useState<any>({});

  const handleClickOutside = (e: any) => {
    if (!!inputRef && inputRef.current && !inputRef.current.contains(e.target)) {
      setTimeout(() => onClose());
    }
  };

  const doFilter = (e: any) => {
    if (!!e && !!e.target) {
      const { target } = e;
      if (target) {
        const { value } = target;
        setFilter(value);
      }
    } else {
      setFilter('');
    }
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      if (!!inputRef && !!inputRef.current) {
        const rect = inputRef.current.getBoundingClientRect();
        const tmpCoords = {
          top: rect.top + rect.height,
          right: document.body.clientWidth - (rect.x + rect.width),
        };
        setCoords(tmpCoords);
        inputRef.current.focus()
      }
    });
  }, [inputRef]);

  useEffect(() => {
    const term = (filter || '').toLowerCase();
    if (!filter) {
      return setLinks([]);
    }
    const tmpSkimpies = (skimpies || []).filter((f: Skimpy) => (f.stageName || '').toLowerCase().indexOf(term) > -1);
    const tmpVenues = (venues || []).filter(
      (f: Venue) => (f.name || '').toLowerCase().indexOf(term) > -1 || (f.suburb || '').toLowerCase().indexOf(term) > -1
    );
    const tmp = [
      ...tmpSkimpies.map((m: Skimpy) => ({ text: m.stageName, to: `/skimpies/${m.skimpyId}` })),
      ...tmpVenues.map((m: Venue) => ({ text: m.name, to: `/venues/${m.venueId}` })),
    ];
    setLinks(tmp);
  }, [filter, setLinks, skimpies, venues]);

  useEffect(() => {
    const root = document.getElementById('root');
    root?.addEventListener('mousedown', handleClickOutside);
    return () => root?.removeEventListener('mousedown', handleClickOutside);
  });

  const handleClose = () => {
    setFilter('');
    onClose();
  };

  const go = (to: string) => {
    history.push(to)
    handleClose()
  }

  return (
    <div className="search-list">
      <input ref={inputRef} type="text" value={filter} onChange={(e) => doFilter(e)} />
      {filter && filter.length ? (
        <button onClick={() => handleClose()}>
          <CloseIcon />
        </button>
      ) : (
        <SearchIcon />
      )}
      <Teleporter selector={`#dropdown-portal`}>
        <div className="search-list-wrapper" style={{ ...coords }}>
          {!!links && !!links.length ? (
            links.map((m) => (
              <div onClick={() => go(m.to)} key={m.to} role="button">
                {m.text}
                <ChevronRightIcon />
              </div>
            ))
          ) : !!filter && filter.length ? (
            <div className="empty">No Results...</div>
          ) : (
            []
          )}
        </div>
      </Teleporter>
    </div>
  );
};

export default SearchList;
