export interface Video {
  id: number;
  path: string;
  isActive: boolean;
}

export const VideoMapper = (apiObj: any): Video => {
  const tmp: Video = {
    id: apiObj.Id,
    path: apiObj.SasPath,
    isActive: apiObj.IsActive
  }
  return tmp
}
