import { useEffect, useMemo, useCallback, useState } from 'react'
import ReactDOM from 'react-dom'

let interval: any = null

const Teleporter = ({ selector, children }: any) => {
  const [mount, setMount] = useState<HTMLElement>()

  const checkMountExists = useCallback((bySelector) => {
    const el = !!bySelector ? document.querySelector(bySelector) : null
    return !!el
  }, [])

  const el = useMemo(() => {
    return document.createElement('div')
  }, [])

  useEffect(() => {
    if (!!selector) {
      interval = setInterval(() => {
        if (!!checkMountExists(selector)) {
          const el = document.querySelector(selector)
          setMount(el)
          clearInterval(interval)
        }
      }, 20)
    }
  }, [selector, checkMountExists, setMount])

  useEffect(() => {
    if (!!el && !!mount) {
      mount.appendChild(el);
    }
    return () => {
      if (mount) {
        mount.removeChild(el)
      }
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [el, mount]);

  return !!el && !!mount ? ReactDOM.createPortal(children, el as HTMLElement) : null;
};

export default Teleporter