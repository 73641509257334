import React, { useEffect, useState } from 'react'
import PlaceholderImg from '../assets/pngs/placeholder_logo.png'


type SkimpyPreviewProps = {
  skimpyName: string;
  skimpyImage: string;
}

const SkimpyPreview = ({skimpyName, skimpyImage}: SkimpyPreviewProps) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [url, setUrl] = useState<string>('')

  useEffect(() => {
    if (!!skimpyImage) {
      const image = new Image()
      image.onload = () => {
        setUrl(skimpyImage as string)
        setLoading(false)
      }
      image.onerror = () => setLoading(false)
      image.src = skimpyImage
    } else {
      setLoading(false)
    }
  }, [skimpyImage])

  return loading ? (
    <span>
      <img src={PlaceholderImg} alt={'loading'}/>
    </span>
  ) : !url ? (
    <img src={PlaceholderImg} alt={`no preview for ${skimpyName}`} />
  ) : (
    <img src={url} alt={`preview for ${skimpyName}`}/>
  )
}

export default SkimpyPreview