import { useContext, useEffect, useCallback } from 'react';
import { ApiDataContext } from '../../contexts/ApiDataContext';
import * as Svc from '../../services/geolocation.svc';

let eventHandler = (event: any) => console.log(event);

const GeolocationListener = () => {
  const { updatePosition } = useContext(ApiDataContext);

  const positionCheck = useCallback(() => {updatePosition && updatePosition();}, [updatePosition])

  useEffect(() => {
    eventHandler = (event) => {
      positionCheck()
    };

    window.addEventListener(Svc.GEOLOCATION_STATUS_UPDATE, eventHandler);
    positionCheck();

    return () => {
      window.removeEventListener(Svc.GEOLOCATION_STATUS_UPDATE, eventHandler);
    };
  }, [positionCheck]);

  return null;
};

export default GeolocationListener;
