import React, { useContext, useEffect, useState } from 'react'
import InlineFlatpickr from '../../components/InlineFlatpickr'

import dayjs from 'dayjs'
import './Date.scss'
import ContactActionButton from '../../components/ContactActionButton'
import { Link } from 'react-router-dom'
import { TabBarAdBlock } from '../../components/TabBarAdBlock'
import { AdsContext } from '../../contexts/AdsContext'
import { ApiDataContext } from '../../contexts/ApiDataContext'

const Date = () => {
  const [date, setDate] = useState(dayjs().toDate())
  const [dateStr, setDateStr] = useState('')
  const {setAdsVenueId} = useContext(AdsContext)
  const {minDate, maxDate} = useContext(ApiDataContext)

  useEffect(() => {
    setDateStr(dayjs(date).format('YYYY-MM-DD'))
  }, [date])

  useEffect(() => {
    // oops name clash on Date - durrrr
    setAdsVenueId && setAdsVenueId(new window.Date().getTime())
  }, [setAdsVenueId])

  const onDateChanged = (d) => {
    setDate(d)
    console.log(d)
  }


  return (
    <div className="page-content date-page">
      <TabBarAdBlock ads={[]} />
      <div className="choose-a-date">
        Choose a Date In The Next 4 Weeks
      </div>
      <InlineFlatpickr onValueChanged={onDateChanged} options={{minDate, maxDate}}/>

      <Link className="plain-btn" to={`/date/${dateStr}`}>Search Date</Link>

      <ContactActionButton to={'/book-skimpies'} text={['Book Skimpies Here']} />
    </div>
  )
}

export default Date