import React from 'react'

import {APP_ENV} from '../config'
import './EnvironmentBanner.scss'

const EnvironmentBanner = () => {
  return APP_ENV.toLowerCase() === 'prod' ? null : (
    <label className="environment-banner">{APP_ENV} environment</label>
  )
}

export default EnvironmentBanner