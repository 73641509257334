// LatLngBounds = [sw: {lat,lng}, ne: {lat,lng}] = 
const westOzBounds = {south: -35.259, west: 112.224, north: -13.279, east: 128.998}

export class PlacesWrapper {
  private api: any;
  private map: any;
  private autoCompleteSvc: any;
  private placesSvc: any;
  private okStatus: any;

  constructor(map: any, api: any) {
    this.map = map
    this.api = api
    this.autoCompleteSvc = new api.places.AutocompleteService()
    this.placesSvc = new api.places.PlacesService(map)
    this.okStatus = api.places.PlacesServiceStatus.OK
  }

  public async searchPlaces(searchTerm: string): Promise<any[]> {
    const req = {
      input: searchTerm.toLowerCase(),
      // fields: ['name', 'geometry.location'],
      bounds: westOzBounds,
      componentRestrictions: {country: 'au'},
    }
    return new Promise((resolve, reject) => {
      this.autoCompleteSvc.getPlacePredictions(req, (res: any, status: any) => {
        if (status === this.okStatus) {
          console.log(res)
          return resolve(res.map((m: any) => ({name: m.description, placeId: m.place_id})))
        } else {
          return reject(status)
        }
      })
    })
  }

  public async getPlace(placeId: string): Promise<any> {
    const req = {
      placeId,
      fields: ['name', 'geometry.location']
    }
    return new Promise((resolve, reject) => {
      this.placesSvc.getDetails(req, (place: any, status: any) => {
        if (status === this.okStatus) {
          console.log(place)
          return resolve(place)
        } else {
          return reject(status)
        }
      })
    })
  }

  public centerOnMap(lat: number, lng: number) {
    this.map.setCenter({lat, lng})
  }
}