import React from 'react';

export function SearchIcon() {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.6992 19.7852L17.4023 15.4883C17.1875 15.3164 16.9297 15.1875 16.6719 15.1875H15.9844C17.1445 13.6836 17.875 11.793 17.875 9.6875C17.875 4.78906 13.8359 0.75 8.9375 0.75C3.99609 0.75 0 4.78906 0 9.6875C0 14.6289 3.99609 18.625 8.9375 18.625C11 18.625 12.8906 17.9375 14.4375 16.7344V17.4648C14.4375 17.7227 14.5234 17.9805 14.7383 18.1953L18.9922 22.4492C19.4219 22.8789 20.0664 22.8789 20.4531 22.4492L21.6562 21.2461C22.0859 20.8594 22.0859 20.2148 21.6992 19.7852ZM8.9375 15.1875C5.88672 15.1875 3.4375 12.7383 3.4375 9.6875C3.4375 6.67969 5.88672 4.1875 8.9375 4.1875C11.9453 4.1875 14.4375 6.67969 14.4375 9.6875C14.4375 12.7383 11.9453 15.1875 8.9375 15.1875Z"
        fill="inherit"
      />
    </svg>
  );
}
