
const notLoaded = () => {throw new Error('analytics not loaded yet')}
const _gtag = () => (window as any).gtag || notLoaded


export const logPageView = (location: any) => {
  try {
    _gtag()('event', 'page_view', {
      page_location: location.pathname
    })
  } catch(e) {
    console.error(e)
  }
}