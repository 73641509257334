
import { LatLng } from "../contexts/ApiDataContext";
import { Venue } from "../models/Venues";

const EARTH_RADIUS_METRES = 6378100;

const {PI, sin, cos, asin, sqrt, pow, floor, atan2} = Math


const hsin = (theta: number): number => {
  return pow(sin(theta/2), 2)
}

const asRadians = (degrees: number): number => degrees * PI / 180
const asDegrees = (radians: number): number => radians * 180 / PI

export const distanceInMeters = (lat1: number, lng1: number, lat2: number, lng2: number): number => {
  const lat1Radians = asRadians(lat1)
  const lng1Radians = asRadians(lng1)
  const lat2Radians = asRadians(lat2)
  const lng2Radians = asRadians(lng2)

  const tmp = hsin(lat1Radians - lat2Radians) + cos(lat1Radians) * cos(lat2Radians) * hsin(lng2Radians - lng1Radians)

  return 2 * EARTH_RADIUS_METRES * asin(sqrt(tmp))
}

export const closestVenues = (myPos: LatLng, venues: Venue[], numVenues: number = 5): Venue[] => {
  const tmp: Venue[] = []

  const orderedByDistance = venues.filter(f => !!f.distanceInMetersFromPoint).sort((a, b) => (a.distanceInMetersFromPoint || 0) - (b.distanceInMetersFromPoint || 0))
  const orderedLength = orderedByDistance.length
  for (let i = 0; i <  numVenues; i++) {
    if (i < orderedLength && !!orderedByDistance[i]) {
      tmp.push(orderedByDistance[i])
    }
  }
  return tmp
}

export const closestVenuesWithinKmRadius = (pos: LatLng, venues: Venue[], kms: number = 5): Venue[] => {
  const tmp = closestVenues(pos, [...venues].map(v => ({...v, distanceInMetersFromPoint: distanceInMeters(pos.lat, pos.lng, v.position?.lat || 0, v.position?.lng || 0)})), venues.length)
  const rangeInMetres = kms * 1000
  const filtered = tmp.filter(f => !!f.distanceInMetersFromPoint && f.distanceInMetersFromPoint <= rangeInMetres).map(m => m.venueId)
  return venues.filter(f => filtered.includes(f.venueId))
}

export const distanceDisplay = (distInMeters: number): string => {
  if (!distInMeters) {
    return ''
  }
  if (distInMeters > 1000) {
    if (distInMeters > 20000) {
      return `${floor(distInMeters / 1000)}km`
    }
    return `${floor(distInMeters / 100) / 10}km`
  }
  return `${floor(distInMeters)}m`
}

export const averageCenterPoint = (positions: LatLng[]): LatLng | null => {
  if (!positions || positions.length < 1) {
    return null
  }
  if (positions.length === 1) {
    return positions[0]
  }

  let x = 0
  let y = 0
  let z = 0

  let latitude = 0
  let longitude = 0

  positions.forEach(p => {
    latitude = asRadians(p.lat)
    longitude = asRadians(p.lng)
    
    x += cos(latitude) * cos(longitude);
    y += cos(latitude) * sin(longitude);
    z += sin(latitude);
  })

  x = x / positions.length
  y = y / positions.length
  z = z / positions.length

  const centralLongitude = atan2(y, x);
  const centralSquareRoot = sqrt(x * x + y * y);
  const centralLatitude = atan2(z, centralSquareRoot);

  return {lat: asDegrees(centralLatitude), lng: asDegrees(centralLongitude)}
}


export const getBoundingBoxForCoords = (positions: LatLng[]): any => {
  const lats = positions.map(m => m.lat)
  const lngs = positions.map(m => m.lng)

  const minLat = Math.min(...lats)
  const maxLat = Math.max(...lats)
  const minLng = Math.min(...lngs)
  const maxLng = Math.max(...lngs)

  return {
    east: maxLng,
    north: maxLat,
    south: minLat,
    west: minLng
  }
}

const WORLD_DIM = { height: 256, width: 256 };
const ZOOM_MAX = 21;


export const getZoomLevelForCoords = (positions: LatLng[], mapDims: {width: number, height: number}, defaultZoom: number): number => {
  if(positions.length <= 1) {
    return defaultZoom || 17
  }

  const zoom = (mapPx: number, worldPx: number, fraction: number): number => {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  const [sw, ne] = getBoundingBoxForCoords(positions)
  const latFraction = (asRadians(ne.lat) - asRadians(sw.lat)) / PI
  const lngDiff = ne.lng - sw.lng
  const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

  const latZoom = zoom(mapDims.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDims.width, WORLD_DIM.width, lngFraction);
  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

(window as any).distInMeters = distanceInMeters