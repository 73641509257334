import React from 'react';

export function ChevronRightIcon() {
  return (
    <svg width="13" height="22" viewBox="0 0 13 22" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3594 11.7969C12.7812 11.375 12.7812 10.6719 12.3594 10.25L3.26562 1.10938C2.79688 0.6875 2.09375 0.6875 1.67188 1.10938L0.59375 2.1875C0.171875 2.60938 0.171875 3.3125 0.59375 3.78125L7.8125 11L0.59375 18.2656C0.171875 18.7344 0.171875 19.4375 0.59375 19.8594L1.67188 20.9375C2.09375 21.3594 2.79688 21.3594 3.26562 20.9375L12.3594 11.7969Z"
        fill="inherit"
      />
    </svg>
  );
}

export function ChevronLeftIcon() {
  return (
    <svg width="13" height="22" viewBox="0 0 13 22" fill="inherit" xmlns="http://www.w3.org/2000/svg" style={{transform: 'scale(-1, 1)'}}>
      <path
        d="M12.3594 11.7969C12.7812 11.375 12.7812 10.6719 12.3594 10.25L3.26562 1.10938C2.79688 0.6875 2.09375 0.6875 1.67188 1.10938L0.59375 2.1875C0.171875 2.60938 0.171875 3.3125 0.59375 3.78125L7.8125 11L0.59375 18.2656C0.171875 18.7344 0.171875 19.4375 0.59375 19.8594L1.67188 20.9375C2.09375 21.3594 2.79688 21.3594 3.26562 20.9375L12.3594 11.7969Z"
        fill="inherit"
      />
    </svg>
  );
}
