import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ContactActionButton from '../../components/ContactActionButton';
import { ApiDataContext } from '../../contexts/ApiDataContext';
import {SortAscendingIcon, SortDescendingIcon, ChevronRightIcon} from '../../components/icons'

import './Skimpies.scss'
import { TabBarAdBlock } from '../../components/TabBarAdBlock';
import { Skimpy } from '../../models/Skimpies';
import { AdsContext } from '../../contexts/AdsContext';

type ListType = {skimpy: Skimpy, children: any}

const Wrapper = ({skimpy, children}: ListType) => {
  const {skimpyId, profileEnabled} = skimpy

  return profileEnabled ? (
    <Link to={`/skimpies/${skimpyId}`} className="skimpy-list-item">
      {children}
    </Link>
  ) : (
    <div className="skimpy-list-item">
      {children}
    </div>
  )
}

const Skimpies = () => {
  const [sortAscending, setSortAscending] = useState(true);
  const { skimpies } = useContext(ApiDataContext);
  const {setAdsVenueId} = useContext(AdsContext)

  useEffect(() => {
    setAdsVenueId && setAdsVenueId((new Date()).getTime())
  }, [setAdsVenueId])

  const sort = () => setSortAscending(!sortAscending);
  return (
    <div className="page-content skimpies-page">
      <TabBarAdBlock />
      <header>
        <h3>Skimpies</h3>
        <button onClick={sort} >
          Name
          {!!sortAscending ? <SortDescendingIcon/> : <SortAscendingIcon />}
        </button>
      </header>
      {skimpies && skimpies.length ? (
        <ul className={!sortAscending ? 'descending' : ''}>
          {skimpies.map((m) => (
            <li key={m.skimpyId}>
              <Wrapper skimpy={m}>
                <label>{m.stageName}</label>
                {m.profileEnabled ? <ChevronRightIcon /> : []}
              </Wrapper>
            </li>
          ))}
        </ul>
      ) : (
        []
      )}
      <ContactActionButton text={['Book Skimpies Here']} to={'/book-skimpies'} />
    </div>
  );
};

export default Skimpies;
