import React from 'react';
import { PhoneIcon } from '../../components/icons';
import GenericImage from '../../assets/pngs/generic_service_image.png';
import './Confirmation.scss';
import TabHeader from '../../components/TabHeader';

const Confirmation = () => {
  return (
    <div className="static-page confirmation">
      <TabHeader />
      <h2>Thank you for your message</h2>
      <p>We will get back to you shortly</p>
      <img src={GenericImage} alt="girl pouring beer" />
      <h3>Call us if this is an urgent, last minute order</h3>
      <a className="btn" href="tel:+61435754679">
        <PhoneIcon />
        0435 SKIMPY
      </a>
    </div>
  );
};

export default Confirmation;
