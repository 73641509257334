import React from 'react'
import FireFlies from './FireFlies'

const SkimpySpinner = () => {

  return (
    <div className="skimpy-spinner-wrapper">
      <FireFlies count={60} />
      <h2>please wait...</h2>
    </div>
  )
}
export default SkimpySpinner