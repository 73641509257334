import React from 'react';

export function DateIcon() {
  return (
    <svg
      width="24"
      height="28"
      viewBox="0 0 24 28"
      fill="inherit"
      fillOpacity="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4286 3.5H18.8571V0.4375C18.8571 0.21875 18.6429 0 18.4286 0H17.5714C17.3036 0 17.1429 0.21875 17.1429 0.4375V3.5H6.85714V0.4375C6.85714 0.21875 6.64286 0 6.42857 0H5.57143C5.30357 0 5.14286 0.21875 5.14286 0.4375V3.5H2.57143C1.125 3.5 0 4.70312 0 6.125V25.375C0 26.8516 1.125 28 2.57143 28H21.4286C22.8214 28 24 26.8516 24 25.375V6.125C24 4.70312 22.8214 3.5 21.4286 3.5ZM22.2857 25.375C22.2857 25.8672 21.8571 26.25 21.4286 26.25H2.57143C2.08929 26.25 1.71429 25.8672 1.71429 25.375V10.5H22.2857V25.375ZM6 21H11.1429C11.5714 21 12 20.6172 12 20.125V14.875C12 14.4375 11.5714 14 11.1429 14H6C5.51786 14 5.14286 14.4375 5.14286 14.875V20.125C5.14286 20.6172 5.51786 21 6 21Z"
        fill="inherit"
        fillOpacity="inherit"
      />
    </svg>
  );
}

export function DateSolidIcon() {
  return (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 17.5938C0 18.5957 0.779297 19.375 1.78125 19.375H14.8438C15.8086 19.375 16.625 18.5957 16.625 17.5938V7.5H0V17.5938ZM2.375 10.4688C2.375 10.1719 2.63477 9.875 2.96875 9.875H6.53125C6.82812 9.875 7.125 10.1719 7.125 10.4688V14.0312C7.125 14.3652 6.82812 14.625 6.53125 14.625H2.96875C2.63477 14.625 2.375 14.3652 2.375 14.0312V10.4688ZM14.8438 2.75H13.0625V0.96875C13.0625 0.671875 12.7656 0.375 12.4688 0.375H11.2812C10.9473 0.375 10.6875 0.671875 10.6875 0.96875V2.75H5.9375V0.96875C5.9375 0.671875 5.64062 0.375 5.34375 0.375H4.15625C3.82227 0.375 3.5625 0.671875 3.5625 0.96875V2.75H1.78125C0.779297 2.75 0 3.56641 0 4.53125V6.3125H16.625V4.53125C16.625 3.56641 15.8086 2.75 14.8438 2.75Z"
        fill="inherit"
      />
    </svg>
  );
}

export function DateGlowIcon() {
  return (
    <svg width="40" height="46" viewBox="0 0 40 46" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path
          d="M31.7857 9.5H28.5714V5.5625C28.5714 5.28125 28.3036 5 28.0357 5H26.9643C26.6295 5 26.4286 5.28125 26.4286 5.5625V9.5H13.5714V5.5625C13.5714 5.28125 13.3036 5 13.0357 5H11.9643C11.6295 5 11.4286 5.28125 11.4286 5.5625V9.5H8.21429C6.40625 9.5 5 11.0469 5 12.875V37.625C5 39.5234 6.40625 41 8.21429 41H31.7857C33.5268 41 35 39.5234 35 37.625V12.875C35 11.0469 33.5268 9.5 31.7857 9.5ZM32.8571 37.625C32.8571 38.2578 32.3214 38.75 31.7857 38.75H8.21429C7.61161 38.75 7.14286 38.2578 7.14286 37.625V18H32.8571V37.625ZM12.5 32H18.9286C19.4643 32 20 31.5078 20 30.875V24.125C20 23.5625 19.4643 23 18.9286 23H12.5C11.8973 23 11.4286 23.5625 11.4286 24.125V30.875C11.4286 31.5078 11.8973 32 12.5 32Z"
          fill="inherit"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="40"
          height="46"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.698039 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
