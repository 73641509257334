import React, {createContext, useContext, useMemo,useState, useCallback} from 'react'
import { Advert } from '../models/Advert'
import { ApiDataContext } from './ApiDataContext'

interface AdsContextType {
  setAdsVenueId: (id: number) => void;
  adsToShow: Advert[];
  setLastAdShown:(ad: Advert) => void;
}
export const AdsContext = createContext<Partial<AdsContextType>>({})


const shuffleAds = (ads: Advert[]): Advert[] => {
  const array = [...ads]
  let curId = array.length;
  // There remain elements to shuffle
  while (0 !== curId) {
    // Pick a remaining element
    let randId = Math.floor(Math.random() * curId);
    curId -= 1;
    // Swap it with the current element.
    let tmp = array[curId];
    array[curId] = array[randId];
    array[randId] = tmp;
  }
  return array;
}

let lastAdShown: Advert | null = null

const adsForId = (adverts: Advert[], id: number): Advert[] => {
  const lastAdId = lastAdShown?.id || -1
  const genericAds = adverts.filter(f => !f.client_id)
  const clientAds = !!id ? adverts.filter(f => f.client_id === id) : []
  if (clientAds.length > 0) {
    return shuffleAds(clientAds)
  }
  const selectableAds = genericAds.length > 1 ? genericAds.filter(f => f.id !== lastAdId) : genericAds
  if (selectableAds.length > 1) {
    const randomAdIndex = Math.floor(Math.random() * selectableAds.length)
    const tmpAd = selectableAds[randomAdIndex]
    return [tmpAd]
  }
  if (selectableAds.length > 0) {
    return [selectableAds[0]]
  }
  return []
}

const AdsProvider = ({children}: any) => {
  const {adverts} = useContext(ApiDataContext)
  const [venueId, setAdsVenueId] = useState<number>(-1)
  
  const setLastAdShown = useCallback((ad: Advert) => { lastAdShown = ad}, [])

  const adsToShow = useMemo(() => {
    if (!adverts || !adverts.length) {
      return []
    }
    return adsForId(adverts, venueId)
  }, [adverts, venueId])


  return (
    <AdsContext.Provider value={{adsToShow, setAdsVenueId, setLastAdShown}}>{children}</AdsContext.Provider>
  )
}

export default AdsProvider
